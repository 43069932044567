const nowDate = new Date()
const year = nowDate.getFullYear()
const dateFrom = `${year}-01-01`
const dateTo = `${year}-01-31`

export default {
  namespaced: true,
  state: {
    dateTo: JSON.parse(localStorage.getItem('auditoria-molex-date-to')) || dateTo,
    dateFrom: JSON.parse(localStorage.getItem('auditoria-molex-date-from')) || dateFrom,
    custom: JSON.parse(localStorage.getItem('auditoria-molex-date-custom')) || '',
    customer: JSON.parse(localStorage.getItem('auditoria-molex-customer')) || null,
    currentPage: JSON.parse(localStorage.getItem('auditoria-molex-current-page')) || 1,
    perPage: JSON.parse(localStorage.getItem('auditoria-molex-per-page')) || 10,
    sortBy: JSON.parse(localStorage.getItem('auditoria-molex-sort-by')) || 'AuditoriaBitacoraEventoID',
    isSortDirDesc: JSON.parse(localStorage.getItem('auditoria-molex-is-sort-dir-desc')) || true,
  },
  getters: {
    dateTo: state => state.dateTo,
    dateFrom: state => state.dateFrom,
    custom: state => state.custom,
    customer: state => state.customer,
    currentPage: state => state.currentPage,
    perPage: state => state.perPage,
    sortBy: state => state.sortBy,
    isSortDirDesc: state => state.isSortDirDesc,
  },
  mutations: {
    DATE_TO(state, data) {
      localStorage.setItem('auditoria-molex-date-to', JSON.stringify(data))
    },
    DATE_FROM(state, data) {
      localStorage.setItem('auditoria-molex-date-from', JSON.stringify(data))
    },
    CUSTOM(state, data) {
      localStorage.setItem('auditoria-molex-date-custom', JSON.stringify(data))
    },
    CUSTOMER(state, data) {
      localStorage.setItem('auditoria-molex-customer', JSON.stringify(data))
    },
    CURRENT_PAGE(state, data) {
      state.currentPage = data
      localStorage.setItem('auditoria-molex-current-page', JSON.stringify(data))
    },
    PER_PAGE(state, data) {
      localStorage.setItem('auditoria-molex-per-page', JSON.stringify(data))
    },
    SORT_BY(state, data) {
      localStorage.setItem('auditoria-molex-sort-by', JSON.stringify(data))
    },
    IS_SORT_DIR_DESC(state, data) {
      localStorage.setItem('auditoria-molex-is-sort-dir-desc', JSON.stringify(data))
    },
  },
  actions: {
    updateParams({ state, commit }, data) {
      state.dateTo = data.paymentDateTo
      commit('DATE_TO', data.paymentDateTo)

      state.dateFrom = data.paymentDateFrom
      commit('DATE_FROM', data.paymentDateFrom)

      state.custom = data.custom
      commit('CUSTOM', data.custom)

      state.customer = data.customer
      commit('CUSTOMER', data.customer)
    },
    updateTable({ state, commit }, data) {
      state.perPage = data.perPage
      commit('PER_PAGE', data.perPage)

      state.sortBy = data.sortBy
      commit('SORT_BY', data.sortBy)

      state.isSortDirDesc = data.isSortDirDesc
      commit('IS_SORT_DIR_DESC', data.isSortDirDesc)
    },
    updateTablePage({ commit }, data) {
      commit('CURRENT_PAGE', data)
    },
  },
}
