import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchParts(ctx, queryParams) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/Partes', { params: queryParams })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchPart(ctx, { parteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Partes/${parteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPart(ctx, partData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Partes', partData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editPart(ctx, partData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Partes/${partData.parteId}`, partData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePart(ctx, { parteId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/Partes/${parteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
