import i18n from '@/libs/i18n'

export default [
  // *=================================================*
  // *--------- Molex  ------------------*
  // *===============================================--*
  {
    path: '/dashboard/auditoria-molex',
    name: 'auditoria-molex',
    meta: {
      resource: 'AuditoriaMolex',
      action: 'read',
      breadcrumb: [
        {
          text: i18n.t('Routes.AuditoriaMolex'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/auditoria-molex/AudiatoriaMolex.vue'),
  },
  {
    path: '/dashboard/auditoria-molex/details/:auditoriaBitacoraEventoID',
    name: 'auditoria-molex-details',
    meta: {
      resource: 'AuditoriaMolex',
      action: 'read',
      breadcrumb: [
        {
          text: i18n.t('Routes.AuditoriaMolex'),
          to: '/dashboard/auditoria-molex',
          active: false,
        },
        {
          text: i18n.t('Routes.AuditoriaMolexDetails'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/dashboard/auditoria-molex/AuditoriaMolexEventosDetalle.vue'),
  },
  {
    path: '/dashboard/auditoria-seguimiento-molex',
    name: 'auditoria-seguimiento-molex',
    meta: {
      resource: 'AuditoriaMolex',
      action: 'read',
    },
    component: () => import('@/views/dashboard/auditoria-molex/AuditoriaSeguimientoMolex.vue'),
  },
  {
    path: '/dashboard/auditoria-saai-m3',
    name: 'auditoria-saai-m3',
    meta: {
      resource: 'AuditoriaMolex',
      action: 'read',
    },
    component: () => import('@/views/dashboard/saai-m3-audit/SaaiM3AuditList.vue'),
  },
  {
    path: '/dashboard/auditoria-previa',
    name: 'auditoria-previa',
    meta: {
      resource: 'AuditoriaMolex',
      action: 'read',
    },
    component: () => import('@/views/dashboard/prev-audit/PrevAuditList.vue'),
  },
]
