import axios from '@axios'
import common from '@/libs/common'

export default function dashboardService(baseEndpoint) {
  const { throwError, throwSuccess } = common()
  const parseStringTemplate = (str, obj) => {
    // eslint-disable-next-line no-useless-escape
    const parts = str.split(/\%\{(?!\d)[\wæøåÆØÅ]*\}/)
    // eslint-disable-next-line no-useless-escape
    const args = str.match(/[^{\}]+(?=})/g) || []
    const parameters = args.map(argument => obj[argument] || (obj[argument] === undefined ? '' : obj[argument]))

    const parsedUrl = String.raw({ raw: parts }, ...parameters)

    return parsedUrl === '' ? '' : `/${parsedUrl}`
  }

  let API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  if (baseEndpoint) {
    API_URL = `${API_URL}/${baseEndpoint}`
  }

  const getApiUrl = (endpoint, routeValues) => `${API_URL}${parseStringTemplate(endpoint, routeValues)}`

  // Basic http methods
  const fetch = (endpoint, routeValues, data, callback) => {
    axios
      .get(getApiUrl(endpoint, routeValues), { params: data })
      .then(response => callback(response.data))
      .catch(error => throwError(error))
  }

  const create = (endpoint, routeValues, toast, data, callback) => {
    axios
      .post(getApiUrl(endpoint, routeValues), data)
      .then(response => {
        callback(response.data)
        if (toast) {
          throwSuccess(response.data)
        }
      })
      .catch(error => throwError(error))
  }

  const update = (endpoint, routeValues, toast, data, callback) => {
    axios
      .put(getApiUrl(endpoint, routeValues), data)
      .then(response => {
        callback(response)
        if (toast) throwSuccess(response)
      })
      .catch(error => throwError(error))
  }

  const remove = (endpoint, routeValues, toast, callback) => {
    axios
      .delete(getApiUrl(endpoint, routeValues))
      .then(response => {
        callback(response)
        if (toast) throwSuccess(response)
      })
      .catch(error => throwError(error))
  }
  const downloadFileByPost = (endpoint, routeValues, data, callback) => {
    axios
      .post(getApiUrl(endpoint, routeValues), data, { responseType: 'blob' })
      .then(response => { callback(response.data) })
      .catch(error => throwError(error))
  }

  return {
    fetch,
    create,
    update,
    remove,
    downloadFileByPost,
  }
}
