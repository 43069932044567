import Vue from 'vue'
// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: `${process.env.VUE_APP_ADUACORE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language': localStorage.getItem('locale') === 'es' ? 'es-MX' : 'en-US',
  },
  // baseURL: 'https://some-domain.com/api/',
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

// {
//   // store.dispatch('app/updateUnreadNotifications')
//   // store.dispatch('app/updateUnreadBulletins')
//   return Promise.response.resolve(response)
// }

axiosIns.interceptors.response.use(response => response, error => {
  // Prevent token expiration
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('userData')
    window.location.reload()
  }

  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
