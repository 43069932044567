import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'

export default function common() {
  const toast = useToast()

  const toastInfo = info => {
    toast({
      component: ToastificationContent,
      props: {
        title: info,
        icon: 'InfoIcon',
      },
    })
  }

  const toastError = error => {
    toast({
      component: ToastificationContent,
      props: {
        title: error,
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    })
  }

  const toastLoading = loading => {
    toast({
      component: ToastificationContent,
      props: {
        title: loading,
        icon: 'LoaderIcon',
        variant: 'warning',
      },
    })
  }

  const toastSuccess = success => {
    toast({
      component: ToastificationContent,
      props: {
        title: success,
        icon: 'CheckCircleIcon',
        variant: 'success',
      },
    })
  }

  const toastSuccessWithInterpolated = (message, params = {}) => {
    const interpolatedMessage = Object.keys(params).reduce(
      (msg, key) => msg.replace(new RegExp(`{${key}}`, 'g'), params[key]),
      message,
    )

    toast({
      component: ToastificationContent,
      props: {
        title: interpolatedMessage,
        icon: 'CheckCircleIcon',
        variant: 'success',
      },
    })
  }

  const toastInfoWithInterpolated = (message, params = {}) => {
    const interpolatedMessage = Object.keys(params).reduce(
      (msg, key) => msg.replace(new RegExp(`{${key}}`, 'g'), params[key]),
      message,
    )

    toast({
      component: ToastificationContent,
      props: {
        title: interpolatedMessage,
        icon: 'CheckCircleIcon',
        variant: 'info',
      },
    })
  }

  const throwError = error => {
    toastError(`${error.response.status}: ${error.response.data}`)
  }

  const throwSuccess = success => {
    if (success.data !== undefined) {
      toastSuccess(`${success.status}: ${success.data}`)
    } else {
      toastSuccess(`${200}: ${success}`)
    }
  }
  const tableSettings = {
    refTable: ref(null),
    title: ref(''),
    perPage: ref(10),
    totalRecords: ref(0),
    currentPage: ref(1),
    perPageOptions: [10, 25, 50, 100],
    searchQuery: ref(''),
    sortBy: ref('id'),
    isSortDirDesc: ref(true),
    columns: [],
  }

  const getUser = () => JSON.parse(localStorage.getItem('userData'))
  const getUserName = () => getUser().username
  const getUserRole = () => getUser().role
  const userIsInRole = role => getUserRole().includes(role)
  const getUserCustoms = () => {
    const user = getUser()
    const { aduanas } = user
    return aduanas
  }

  const containsUserCustom = custom => {
    const customs = getUserCustoms()
    if (customs) {
      if (Array.isArray(customs)) {
        return customs.includes(custom)
      }
    }

    return custom === customs
  }

  const userOnlyContainCustom = custom => {
    const customs = getUserCustoms()
    return customs === custom
  }

  const resizeImage = img => {
    const maxWidth = 1024
    const maxHeight = 1024

    const canvas = document.createElement('canvas')
    let { width } = img
    let { height } = img

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > maxWidth) {
        height = Math.round(height *= maxWidth / width)
        width = maxWidth
      }
    } else if (height > maxHeight) {
      width = Math.round(width *= maxHeight / height)
      height = maxHeight
    }
    // resize the canvas and draw the image data into it
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(img, 0, 0, width, height)

    return canvas
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`
  }

  const formatDate = date => {
    const pad = (num, size) => num.toString().padStart(size, '0')

    const day = pad(date.getDate(), 2)
    const month = pad(date.getMonth() + 1, 2)
    const year = date.getFullYear()
    const hours = pad(date.getHours(), 2)
    const minutes = pad(date.getMinutes(), 2)
    const seconds = pad(date.getSeconds(), 2)
    const milliseconds = pad(date.getMilliseconds(), 3)

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}.${milliseconds}`
  }

  return {
    toastInfo,
    toastError,
    toastLoading,
    toastSuccess,
    toastSuccessWithInterpolated,
    toastInfoWithInterpolated,
    throwError,
    throwSuccess,
    tableSettings,
    getUser,
    getUserName,
    getUserRole,
    userIsInRole,
    containsUserCustom,
    userOnlyContainCustom,
    resizeImage,
    formatBytes,
    formatDate,
  }
}
