import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
/* import the fontawesome core */
// import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
// import { faBookAtlas } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add icons to the library */
// library.add(faBookAtlas)
Vue.component(FeatherIcon.name, FeatherIcon)
// Vue.component('font-awesome-icon', FontAwesomeIcon)
