import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchVendors(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Proveedores', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchVendor(ctx, { proveedorId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Proveedores/${proveedorId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addVendor(ctx, vendorData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Proveedores', vendorData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editVendor(ctx, vendorData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Proveedores/${vendorData.proveedorId}`, vendorData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVendor(ctx, { proveedorId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/Proveedores/${proveedorId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Clientes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { clienteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Clientes/${clienteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
