import i18n from '@/libs/i18n'

export default [
  // *===============================================---*
  // *--------- ConditionFilters  -----------------------------------------------------*
  // *===============================================---*

  {
    path: '/settings/dataaccess/conditionfilters',
    name: 'settings-dataaccess-conditionfilters',
    component: () => import('@/views/settings/data-access/ConditionFilters/ConditionFilters.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.conditionFiltersList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.conditionFiltersList'),
          active: true,
          to: '/settings/dataaccess/conditionfilters',
        },
      ],
    },
  },
  {
    path: '/settings/dataaccess/conditionfilters/add',
    name: 'settings-dataaccess-conditionfilters-add',
    component: () => import('@/views/settings/data-access/ConditionFilters/ConditionFiltersAddEdit.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.conditionFilterNew'),
      breadcrumb: [
        {
          text: i18n.t('Routes.conditionFiltersList'),
          active: false,
          to: '/settings/dataaccess/conditionfilters',
        },
        {
          text: i18n.t('Routes.conditionFilterNew'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/dataaccess/conditionfilters/edit',
    name: 'settings-dataaccess-conditionfilters-edit',
    component: () => import('@/views/settings/data-access/ConditionFilters/ConditionFiltersAddEdit.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.editConditionFilter'),
      breadcrumb: [
        {
          text: i18n.t('Routes.conditionFiltersList'),
          active: false,
          to: '/settings/dataaccess/conditionfilters',
        },
        {
          text: i18n.t('Routes.editConditionFilter'),
          active: true,
        },
      ],
    },
  },
  // *===============================================---*
  // *--------- Conncection Strings ------------------------------------------------*
  // *===============================================---*
  {
    path: '/settings/dataaccess/connectionstrings/',
    name: 'settings-dataaccess-connectionstrings',
    component: () => import('@/views/settings/data-access/ConnectionStrings/ConnectionStrings.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.connectionStringsFiltersList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.connectionStringsFiltersList'),
          active: true,
          to: '/settings/dataaccess/connectionstrings',
        },
      ],
    },
  },
  {
    path: '/settings/dataaccess/connectionstrings/edit',
    name: 'settings-dataaccess-connectionstrings-edit',
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.editConditionFilter'),
      breadcrumb: [
        {
          text: i18n.t('Routes.connectionStringsFiltersList'),
          active: false,
          to: '/settings/dataaccess/connectionstrings',
        },
        {
          text: i18n.t('Routes.editConnectionString'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/settings/data-access/ConnectionStrings/ConnectionStringsEdit.vue'),
  },
  // *===============================================---*
  // *--------- QueryObjects  -------------------------------------------------------*
  // *===============================================---*
  {
    path: '/settings/dataaccess/queryobjects',
    name: 'settings-dataaccess-queryobjects',
    component: () => import('@/views/settings/data-access/QueryObjects/QueryObjects.vue'),
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.queryObjectsList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.queryObjectsList'),
          active: true,
          to: '/settings/dataaccess/queryobjects',
        },
      ],
    },
  },
  {
    path: '/settings/dataaccess/QueryObject/edit',
    name: 'settings-dataaccess-QueryObject-edit',
    meta: {
      resource: 'Auth',
      action: 'read',
      pageTitle: i18n.t('Routes.editQueryObject'),
      breadcrumb: [
        {
          text: i18n.t('Routes.queryObjectsList'),
          active: false,
          to: '/settings/dataaccess/queryobjects',
        },
        {
          text: i18n.t('Routes.editQueryObject'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/settings/data-access/QueryObjects/QueryObjectsEdit'),
  },
  // *===============================================---*
  // *--------- Dashboards -----------------------------*
  // *===============================================---*
  {
    path: '/settings/dashboards/elements',
    name: 'settings-dashboards-elements',
    component: () => import('@/views/settings/dashboards/elements/DashboardElementsList.vue'),
    meta: {
      resource: 'Tableros',
      action: 'read',
      pageTitle: i18n.t('Routes.dashboardElementsList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.dashboardElementsList'),
          active: true,
          to: '/settings/dashboards/elements',
        },
      ],
    },
  },
  {
    path: '/settings/dashboards/elements/new',
    name: 'settings-dashboards-elements-new',
    component: () => import('@/views/settings/dashboards/elements/DashboardElementsListAddEdit.vue'),
    meta: {
      resource: 'Tableros',
      action: 'write',
      pageTitle: i18n.t('Routes.dashboardElementAdd'),
      breadcrumb: [
        {
          text: i18n.t('Routes.dashboardElementsList'),
          active: false,
          to: '/settings/dashboards/elements',
        },
        {
          text: i18n.t('Routes.dashboardElementAdd'),
          active: true,
          to: '/settings/dashboards/elements/new',
        },
      ],
    },
  },
  {
    path: '/settings/dashboards/elements/:elementoId/edit',
    name: 'settings-dashboards-elements-edit',
    component: () => import('@/views/settings/dashboards/elements/DashboardElementsListAddEdit.vue'),
    meta: {
      resource: 'Tableros',
      action: 'write',
      pageTitle: i18n.t('Routes.dashboardElementEdit'),
      breadcrumb: [
        {
          text: i18n.t('Routes.dashboardElementsList'),
          active: false,
          to: '/settings/dashboards/elements',
        },
        {
          text: i18n.t('Routes.dashboardElementEdit'),
          active: true,
          to: '/settings/dashboards/elements/:elementoId/edit',
        },
      ],
    },
  },
]
