import axios from '@axios'
import common from '@/libs/common'

export default function optionService() {
  const { throwError } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */
  const fetchOptions = (domainRequest, callback) => {
    axios
      .get(`${API_URL}/Opciones`, { params: domainRequest })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchTextOptions = (domainRequest, callback) => {
    axios
      .get(`${API_URL}/Opciones/Texto`, { params: domainRequest })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchOptionsPromise = domainRequest => new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/Opciones`, { params: domainRequest })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
  // (domainRequest => {
  //   axios
  //     .get(`${API_URL}/Opciones`, { params: domainRequest })
  //     .then(response => {
  //       callback(response.data)
  //     })
  //     .catch(error => throwError(error))
  // }

  const fecthCustoms = callback => {
    axios
      .get(`${API_URL}/Opciones`, { params: { nombreDominio: 'Aduanas' } })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPatents = callback => {
    axios
      .get(`${API_URL}/Opciones/Patentes`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  // General ----------------------------------------
  const fetchCustomers = callback => {
    axios
      .get(`${API_URL}/Opciones`, { params: { nombreDominio: 'Clientes' } })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchVendors = (callback, clienteId) => {
    axios
      .get(`${API_URL}/Opciones`, { params: { nombreDominio: 'Proveedores', llaveBuscar: clienteId } })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchFracciones = callback => {
    axios
      .get(`${API_URL}/Opciones/Fracciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchNicos = (callback, fraccionArancelariaId) => {
    axios
      .get(`${API_URL}/Opciones/Nicos`, { params: { fraccionArancelariaId } })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchOperationTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/OperacionTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchSystems = callback => {
    axios
      .get(`${API_URL}/Opciones/Sistemas`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchUnidadesMedida = callback => {
    axios
      .get(`${API_URL}/Opciones/UnidadesMedida`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchUnidadesMedidaInternacionales = callback => {
    axios
      .get(`${API_URL}/Opciones/UnidadesMedida/Internacional`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchVendorDocumentTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/DocumentoProveedorTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchDocumentoClavesUnicos = callback => {
    axios
      .get(`${API_URL}/Opciones/DocumentoClavesUnicos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchDocumentTypes = (operationTypeId, callback) => {
    axios
      .get(`${API_URL}/Opciones/DocumentoClaves/${operationTypeId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchAuditoriaGrupoEventos = callback => {
    axios
      .get(`${API_URL}/Opciones/AuditoriaGrupoEventos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchCustomersSysExpertOptions = callback => {
    axios
      .get(`${API_URL}/Clientes/SysExpert/Opciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPartsSysExpertOptions = (claveCliente, tipoImpoExpo, callback) => {
    axios
      .get(`${API_URL}/Partes/SysExpert/Opciones/${claveCliente}`, { params: { tipoImpoExpo } })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchDashboardElementTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/TableroElementoTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPartMaterialTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/ParteMaterialTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchClassificationRequestTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/ClasificacionSolicitudTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchParts = (clienteId, callback) => {
    axios
      .get(`${API_URL}/Opciones/Partes/${clienteId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchUsers = callback => {
    axios
      .get(`${API_URL}/Opciones/Usuarios`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetcBulletinTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/BoletinTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchClaimsTypes = callback => {
    axios
      .get(`${API_URL}/Opciones/ClaimTipos`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchOptions,
    fetchTextOptions,
    fetchOptionsPromise,
    fecthCustoms,
    fetchPatents,
    fetchCustomers,
    fetchVendors,
    fetchFracciones,
    fetchNicos,
    fetchOperationTypes,
    fetchSystems,
    fetchUnidadesMedida,
    fetchUnidadesMedidaInternacionales,
    fetchVendorDocumentTypes,
    fetchDocumentoClavesUnicos,
    fetchDocumentTypes,
    fetchAuditoriaGrupoEventos,
    fetchCustomersSysExpertOptions,
    fetchPartsSysExpertOptions,
    fetchDashboardElementTypes,
    fetchPartMaterialTypes,
    fetchClassificationRequestTypes,
    fetchParts,
    fetcBulletinTypes,
    fetchClaimsTypes,
    fetchUsers,
  }
}
