import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchProfile() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/profile`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/profile`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    partialUpdateProfile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/profile`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfilePicture(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/profile/picture`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteProfile() {
      return new Promise((resolve, reject) => {
        axios
          .delete(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateProfilePassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/password`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createProfilePassword(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/password`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    checkProfilePassword() {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/password`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    profileLogs(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL}/accounts/logs`, { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
