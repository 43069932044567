import axios from '@axios'
import common from '@/libs/common'

export default function accountService() {
  const { throwError } = common()

  const API_URL = process.env.VUE_APP_ADUACORE_JPMANAGEMENT_API_URL

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchProfile = callback => {
    axios
      .get(`${API_URL}/accounts/profile`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateProfile = (data, callback) => {
    axios
      .put(`${API_URL}/accounts/profile`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const partialUpdateProfile = (data, callback) => {
    axios
      .patch(`${API_URL}/accounts/profile`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateProfilePicture = (data, callback) => {
    axios
      .put(`${API_URL}/accounts/profile/picture`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteOwnProfile = callback => {
    axios
      .delete(`${API_URL}/accounts`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateProfilePassword = (data, callback) => {
    axios
      .put(`${API_URL}/accounts/password`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createProfilePassword = (data, callback) => {
    axios
      .post(`${API_URL}/accounts/password`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchProfilePassword = callback => {
    axios
      .get(`${API_URL}/accounts/password`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchProfileLogs = (data, callback) => {
    axios
      .get(`${API_URL}/accounts/logs`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchAbilities = callback => {
    axios
      .get('Perfil/ReglasAcceso')
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const fetchNotificationsSettings = callback => {
    axios
      .get(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Perfil/ConfiguracionNotificaciones`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updateNotificationsSettings = (data, callback) => {
    axios
      .put(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Perfil/ConfiguracionNotificaciones`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchUnreadNotificationsCount = callback => {
    axios
      .get('Perfil/Notificaciones/SinLeer')
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchNotificationsReadAll = () => {
    axios
      .get('Perfil/Notificaciones/LeerTodo')
      .then()
      .catch(error => throwError(error))
  }

  const fetchNotifications = callback => {
    axios
      .get('Perfil/Notificaciones')
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchUnreadBulletinsCount = callback => {
    axios
      .get(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Perfil/Boletines/SinLeer`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchBulletins = (data, callback) => {
    axios
      .get(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Perfil/Boletines`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchBulletinsReadAll = () => {
    axios
      .get(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Perfil/Boletines/LeerTodo`)
      .then()
      .catch(error => throwError(error))
  }

  return {
    fetchProfile,
    updateProfile,
    partialUpdateProfile,
    updateProfilePicture,
    deleteOwnProfile,
    updateProfilePassword,
    createProfilePassword,
    fetchProfilePassword,
    fetchProfileLogs,
    fetchAbilities,
    fetchUnreadNotificationsCount,
    fetchNotificationsReadAll,
    fetchNotifications,
    fetchNotificationsSettings,
    updateNotificationsSettings,
    fetchUnreadBulletinsCount,
    fetchBulletins,
    fetchBulletinsReadAll,
  }
}
