import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTransportationLines(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/GeneralDatos/LineasTransporte', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTransportationLine(ctx, { lineaTransporteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/GeneralDatos/LineasTransporte/${lineaTransporteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTransportationLine(ctx, TransportationLineData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/GeneralDatos/LineasTransporte', TransportationLineData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editTransportationLine(ctx, TransportationLineData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/GeneralDatos/LineasTransporte/${TransportationLineData.lineaTransporteId}`, TransportationLineData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTransportationLine(ctx, { lineaTransporteId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/GeneralDatos/LineaTransporte/${lineaTransporteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
