import i18n from '@/libs/i18n'

export default [
  // *=================================================*
  // *--------- RAM ------------------------------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/requirement/list',
    name: 'apps-requirement-list',
    meta: {
      resource: 'AccionesMejora',
      action: 'read',
      pageTitle: i18n.t('Routes.RAMList'),
      breadcrumb: [
        {
          to: '/apps/requirement/list',
          text: i18n.t('Routes.RAMList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/ram/requirement/RequirementList.vue'),
  },
  {
    path: '/apps/requirement/new',
    name: 'apps-requirement-new',
    meta: {
      resource: 'AccionesMejora',
      action: 'create',
      pageTitle: i18n.t('Routes.RAMList'),
      breadcrumb: [
        {
          to: '/apps/requirement/list',
          text: i18n.t('Routes.RAMList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/ram/requirement/RequirementAddEdit.vue'),
  },
  {
    path: '/apps/requirement/edit/:requerimientoId',
    name: 'apps-requirement-edit',
    meta: {
      resource: 'AccionesMejora',
      action: 'write',
      pageTitle: i18n.t('Routes.RAMEdit'),
      breadcrumb: [
        {
          to: '/apps/requirement/list',
          text: i18n.t('Routes.RAMList'),
          active: false,
        },
        {
          to: '/apps/requirement/edit/:requerimientoId',
          text: i18n.t('Routes.RAMEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/ram/requirement/RequirementAddEdit.vue'),
  },
  // *=================================================*
  // *--------- RequirementQualityType-------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/requirement-quality-type/list',
    name: 'apps-requirement-quality-type-list',
    meta: {
      resource: 'AccionesMejora',
      action: 'read',
      pageTitle: i18n.t('Routes.RequirementQualityTypeList'),
      breadcrumb: [
        {
          to: '/apps/requirement-quality-type/list',
          text: i18n.t('Routes.RequirementQualityTypeList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/requirement-quality-type/RequirementQualityType.vue'),
  },
  {
    path: '/apps/requirement-quality-type/edit/:requerimientoCalidadTipoId',
    name: 'apps-requirement-quality-type-edit',
    meta: {
      resource: 'AccionesMejora',
      action: 'write',
      pageTitle: i18n.t('Routes.EditRequirementQualityType'),
      breadcrumb: [
        {
          to: '/apps/requirement-quality-type/list',
          text: i18n.t('Routes.RequirementQualityTypeList'),
          active: false,
        },
        {
          to: '/apps/requirement-quality-type/edit/:requerimientoCalidadTipoId',
          text: i18n.t('Routes.EditRequirementQualityType'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/requirement-quality-type/RequirementQualityTypeEdit.vue'),
  },
  // *=================================================*
  // *--------- RequirementDocumentType-------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/requirement-document-type/list',
    name: 'apps-requirement-document-type-list',
    meta: {
      resource: 'AccionesMejora',
      action: 'read',
      pageTitle: i18n.t('Routes.RequirementDocumentTypeList'),
      breadcrumb: [
        {
          to: '/apps/requirement-document-type/list',
          text: i18n.t('Routes.RequirementDocumentTypeList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/requirement-document-type/RequirementDocumentType.vue'),
  },
  {
    path: '/apps/requirement-document-type/edit/:requerimientoDocumentoTipoId',
    name: 'apps-requirement-document-type-edit',
    meta: {
      resource: 'AccionesMejora',
      action: 'write',
      pageTitle: i18n.t('Routes.EditRequirementDocumentType'),
      breadcrumb: [
        {
          to: '/apps/requirement-document-type/list',
          text: i18n.t('Routes.RequirementDocumentTypeList'),
          active: false,
        },
        {
          to: '/apps/requirement-document-type/edit/:requerimientoDocumentoTipoId',
          text: i18n.t('Routes.EditRequirementDocumentType'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/requirement-document-type/RequirementDocumentTypeEdit.vue'),
  },
  // *=================================================*
  // *--------- Indicator  ------------------------------------------- ------*
  // *===============================================---*
  {
    path: '/apps/indicator/list',
    name: 'apps-indicator-list',
    meta: {
      resource: 'GeneralData',
      action: 'read',
      pageTitle: i18n.t('Routes.IndicatorList'),
      breadcrumb: [
        {
          to: '/apps/indicator/list',
          text: i18n.t('Routes.IndicatorList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/indicator/IndicatorList.vue'),
  },
  {
    path: '/apps/indicator/edit/:indicadorId',
    name: 'apps-indicator-edit',
    meta: {
      resource: 'GeneralData',
      action: 'write',
      pageTitle: i18n.t('Routes.EditIndicator'),
      breadcrumb: [
        {
          to: '/apps/indicator/list',
          text: i18n.t('Routes.IndicatorList'),
          active: false,
        },
        {
          to: '/apps/indicator/:indicadorId',
          text: i18n.t('Routes.EditIndicator'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/indicator/IndicatorEdit.vue'),
  },
  // *=================================================*
  // *--------- ActionImprovementProblem  ------------------------------------------- ------*
  // *===============================================---*
  {
    path: '/apps/action-improvement-problem/list',
    name: 'apps-action-improvement-problem-list',
    meta: {
      resource: 'GeneralData',
      action: 'read',
      pageTitle: i18n.t('Routes.ActionImprovementProblemList'),
      breadcrumb: [
        {
          to: '/apps/action-improvement-problem/list',
          text: i18n.t('Routes.ActionImprovementProblemList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/action-improvement-problem/ActionImprovementProblem.vue'),
  },
  {
    path: '/apps/action-improvement-problem/edit/:accionMejoraProblemaId',
    name: 'apps-action-improvement-problem-edit',
    meta: {
      resource: 'GeneralData',
      action: 'write',
      pageTitle: i18n.t('Routes.EditActionImprovementProblem'),
      breadcrumb: [
        {
          to: '/apps/action-improvement-problem/list',
          text: i18n.t('Routes.ActionImprovementProblemList'),
          active: false,
        },
        {
          to: '/apps/action-improvement-problem/edit/:accionMejoraProblemaId',
          text: i18n.t('Routes.EditActionImprovementProblem'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/action-improvement-problem/ActionImprovementProblemEdit.vue'),
  },
  // *=================================================*
  // *--------- Work Area  ------------------------------------------- ------*
  // *===============================================---*
  {
    path: '/apps/human-resources/work-area/list',
    name: 'apps-human-resources-work-area-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhWorkAreasList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/work-area/list',
          text: i18n.t('Routes.RhWorkAreasList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/work-area/WorkAreaList.vue'),
  },
  {
    path: '/apps/human-resources/work-area/edit/:areaTrabajoId',
    name: 'apps-human-resources-work-area-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.RhEditWorkAreasList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/work-area/list',
          text: i18n.t('Routes.RhWorkAreasList'),
          active: false,
        },
        {
          to: '/apps/human-resources/work-area/edit/:areaTrabajoId',
          text: i18n.t('Routes.RhEditWorkAreasList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/work-area/WorkAreaEdit.vue'),
  },
  // *=================================================*
  // *--------- Departament  ------------------------------------------- ------*
  // *===============================================---*
  {
    path: '/apps/human-resources/departament/list',
    name: 'apps-human-resources-departament-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhDepartmentsList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/departament/list',
          text: i18n.t('Routes.RhDepartmentsList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/departament/DepartamentList.vue'),
  },
  {
    path: '/apps/human-resources/departament/edit/:departamentoId',
    name: 'apps-human-resources-departament-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.customsList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/departament/list',
          text: i18n.t('Routes.RhDepartmentsList'),
          active: false,
        },
        {
          to: '/apps/human-resources/departament/edit/:departamentoId',
          text: i18n.t('Routes.RhEditDepartments'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/departament/DepartamentEdit.vue'),
  },
  // *=================================================*
  // *--------- Scholarship  ------------------------------------------- ------*
  // *===============================================---*
  {
    path: '/apps/human-resources/scholarship/list',
    name: 'apps-human-resources-scholarship-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhScholarshipList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/scholarship/list',
          text: i18n.t('Routes.RhScholarshipList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/scholarship/ScholarshipList.vue'),
  },
  {
    path: '/apps/human-resources/scholarship/edit/:escolaridadId',
    name: 'apps-human-resources-scholarship-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.RhEditScholarship'),
      breadcrumb: [
        {
          to: '/apps/human-resources/scholarship/list',
          text: i18n.t('Routes.RhScholarshipList'),
          active: false,
        },
        {
          to: '/apps/human-resources/scholarship/edit/:escolaridadId',
          text: i18n.t('Routes.RhEditScholarship'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/scholarship/ScholarshipEdit.vue'),
  },
  // *=================================================*
  // *--------- CivilStatus  ------------------------------------------- ------*
  // *===============================================---*
  {
    path: '/apps/human-resources/civil-status/list',
    name: 'apps-human-resources-civil-status-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhCivilStatusList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/civil-status/list',
          text: i18n.t('Routes.RhCivilStatusList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/civil-status/CivilStatusList.vue'),
  },
  {
    path: '/apps/human-resources/civil-status/edit/:estadoCivilId',
    name: 'apps-human-resources-civil-status-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.RhCivilStatusList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/civil-status/list',
          text: i18n.t('Routes.RhCivilStatusList'),
          active: false,
        },
        {
          to: '/apps/human-resources/civil-status/edit/:estadoCivilId',
          text: i18n.t('Routes.RhEditCivilStatus'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/civil-status/CivilStatusEdit.vue'),
  },
  // *=================================================*
  // *--------- WorkPlace  -----------------------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/human-resources/work-place/list',
    name: 'apps-human-resources-work-place-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhWorkPlacesList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/work-place/list',
          text: i18n.t('Routes.RhWorkPlacesList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/work-place/WorkPlaceList.vue'),
  },
  {
    path: '/apps/human-resources/work-place/edit/:lugarTrabajoId',
    name: 'apps-human-resources-work-place-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.RhEditWorkPlace'),
      breadcrumb: [
        {
          to: '/apps/human-resources/work-place/list',
          text: i18n.t('Routes.RhWorkPlacesList'),
          active: false,
        },
        {
          to: '/apps/human-resources/work-place/edit/:lugarTrabajoId',
          text: i18n.t('Routes.RhEditWorkPlace'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/work-place/WorkPlaceEdit.vue'),
  },
  // *=================================================*
  // *--------- WorkStation  -----------------------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/human-resources/work-station/list',
    name: 'apps-human-resources-work-station-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhWorkStationsList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/work-station/list',
          text: i18n.t('Routes.RhWorkStationsList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/work-station/WorkStationList.vue'),
  },
  {
    path: '/apps/human-resources/work-station/edit/:puestoTrabajoId',
    name: 'apps-human-resources-work-station-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.RhEditWorkStations'),
      breadcrumb: [
        {
          to: '/apps/human-resources/work-station/list',
          text: i18n.t('Routes.RhWorkStationsList'),
          active: false,
        },
        {
          to: '/apps/human-resources/work-station/edit/:puestoTrabajoId',
          text: i18n.t('Routes.RhEditWorkStations'),
          active: false,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/work-station/WorkStationEdit.vue'),
  },
  // *=================================================*
  // *--------- EmployeeType  -----------------------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/human-resources/employee-type/list',
    name: 'apps-human-resources-employee-type-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.RhEmployeeTypesList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/employee-type/list',
          text: i18n.t('Routes.RhEmployeeTypesList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/employee-types/EmployeeTypeList.vue'),
  },
  {
    path: '/apps/human-resources/employee-type/edit/:tipoEmpleadoId',
    name: 'apps-human-resources-employee-type-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'write',
      pageTitle: i18n.t('Routes.RhEditEmployeeType'),
      breadcrumb: [
        {
          to: '/apps/human-resources/employee-type/list',
          text: i18n.t('Routes.RhEmployeeTypesList'),
          active: false,
        },
        {
          to: '/apps/human-resources/employee-type/edit/:tipoEmpleadoId',
          text: i18n.t('Routes.RhEditEmployeeType'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/employee-types/EmployeeTypeEdit.vue'),
  },
  // *=================================================*
  // *--------- Staff requests  -----------------------*
  // *===============================================--*
  // {
  //   path: '/apps/human-resources/staff-request/list',
  //   name: 'apps-human-resources-staff-request-list',
  //   meta: {
  //     resource: 'RecursosHumanos',
  //     action: 'read',
  //     pageTitle: i18n.t('Routes.RhStaffRequestList'),
  //     breadcrumb: [
  //       {
  //         to: '/apps/human-resources/staff-request/list',
  //         text: i18n.t('Routes.RhStaffRequestList'),
  //         active: true,
  //       },
  //     ],
  //   },
  //   component: () => import('@/views/apps/human-resources/staff-request/StaffRequestList.vue'),
  // },
  // {
  //   path: '/apps/human-resources/staff-request/add',
  //   name: 'apps-human-resources-staff-request-add',
  //   meta: {
  //     resource: 'RecursosHumanos',
  //     action: 'create',
  //     pageTitle: i18n.t('Routes.RhStaffRequestAdd'),
  //     breadcrumb: [
  //       {
  //         to: '/apps/human-resources/staff-request/list',
  //         text: i18n.t('Routes.RhStaffRequestList'),
  //         active: false,
  //       },
  //       {
  //         to: '/apps/human-resources/staff-request/list',
  //         text: i18n.t('Routes.RhStaffRequestAdd'),
  //         active: true,
  //       },
  //     ],
  //   },
  //   component: () => import('@/views/apps/human-resources/staff-request/StaffRequestAddEdit.vue'),
  // },
  // {
  //   path: '/apps/human-resources/staff-request/edit/:requisicionPersonalId',
  //   name: 'apps-human-resources-staff-request-edit',
  //   meta: {
  //     resource: 'RecursosHumanos',
  //     action: 'create',
  //     pageTitle: i18n.t('Routes.RhStaffRequestEdit'),
  //     breadcrumb: [
  //       {
  //         to: '/apps/human-resources/staff-request/list',
  //         text: i18n.t('Routes.RhStaffRequestList'),
  //         active: false,
  //       },
  //       {
  //         to: '/apps/human-resources/staff-request/edit',
  //         text: i18n.t('Routes.RhStaffRequestEdit'),
  //         active: true,
  //       },
  //     ],
  //   },
  //   component: () => import('@/views/apps/human-resources/staff-request/StaffRequestAddEdit.vue'),
  // },
  // *===============================================---*
  // *---------ClassContractt-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/class-contract/list',
    name: 'apps-human-resources-class-contract-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ClassContractList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/class-contract/list',
          text: i18n.t('Routes.ClassContractList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/class-contract/ClassContractList.vue'),
  },
  {
    path: '/apps/human-resources/class-contract/add',
    name: 'apps-human-resources-class-contract-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ClassContractAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/class-contract/list',
          text: i18n.t('Routes.ClassContractList'),
          active: false,
        },
        {
          to: '/apps/human-resources/class-contract/add',
          text: i18n.t('Routes.ClassContractAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/class-contract/ClassContractAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/class-contract/edit/:claseContratoId',
    name: 'apps-human-resources-class-contract-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ClassContractEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/class-contract/list',
          text: i18n.t('Routes.ClassContractList'),
          active: false,
        },
        {
          to: '/apps/human-resources/class-contract/edit',
          text: i18n.t('Routes.ClassContractEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/class-contract/ClassContractAddEdit.vue'),
  },
  // *===============================================---*
  // *---------LaboralDedication-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/laboral-dedication/list',
    name: 'apps-human-resources-laboral-dedication-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LaboralDedicationList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/laboral-dedication/list',
          text: i18n.t('Routes.LaboralDedicationList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/laboral-dedication/LaboralDedicationList.vue'),
  },
  {
    path: '/apps/human-resources/laboral-dedication/add',
    name: 'apps-human-resources-laboral-dedication-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LaboralDedicationAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/laboral-dedication/list',
          text: i18n.t('Routes.LaboralDedicationList'),
          active: false,
        },
        {
          to: '/apps/human-resources/laboral-dedication/add',
          text: i18n.t('Routes.LaboralDedicationAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/laboral-dedication/LaboralDedicationAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/laboral-dedication/edit/:dedicacionLaboralId',
    name: 'apps-human-resources-laboral-dedication-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LaboralDedicationEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/laboral-dedication/list',
          text: i18n.t('Routes.LaboralDedicationList'),
          active: false,
        },
        {
          to: '/apps/human-resources/laboral-dedication/edit',
          text: i18n.t('Routes.LaboralDedicationEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/laboral-dedication/LaboralDedicationAddEdit.vue'),
  },
  // *===============================================---*
  // *---------SchemeTypePayment-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/scheme-type-payment/list',
    name: 'apps-human-resources-scheme-type-payment-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.SchemeTypePaymentList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/scheme-type-payment/list',
          text: i18n.t('Routes.SchemeTypePaymentList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/scheme-type-payment/SchemeTypePaymentList.vue'),
  },
  {
    path: '/apps/human-resources/scheme-type-payment/add',
    name: 'apps-human-resources-scheme-type-payment-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.SchemeTypePaymentAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/scheme-type-payment/list',
          text: i18n.t('Routes.SchemeTypePaymentList'),
          active: false,
        },
        {
          to: '/apps/human-resources/scheme-type-payment/add',
          text: i18n.t('Routes.SchemeTypePaymentAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/scheme-type-payment/SchemeTypePaymentAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/scheme-type-payment/edit/:esquemaPagoTipoId',
    name: 'apps-human-resources-scheme-type-payment-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.SchemeTypePaymentEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/scheme-type-payment/list',
          text: i18n.t('Routes.SchemeTypePaymentList'),
          active: false,
        },
        {
          to: '/apps/human-resources/scheme-type-payment/edit',
          text: i18n.t('Routes.SchemeTypePaymentEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/scheme-type-payment/SchemeTypePaymentAddEdit.vue'),
  },
  // *===============================================---*
  // *---------AcademicTraining-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/academic-training/list',
    name: 'apps-human-resources-academic-training-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.AcademicTrainingList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/academic-training/list',
          text: i18n.t('Routes.AcademicTrainingList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/academic-training/AcademicTrainingList.vue'),
  },
  {
    path: '/apps/human-resources/academic-training/add',
    name: 'apps-human-resources-academic-training-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.AcademicTrainingAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/academic-training/list',
          text: i18n.t('Routes.AcademicTrainingList'),
          active: false,
        },
        {
          to: '/apps/human-resources/academic-training/add',
          text: i18n.t('Routes.AcademicTrainingAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/academic-training/AcademicTrainingAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/academic-training/edit/:formacionAcademicaId',
    name: 'apps-human-resources-academic-training-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.AcademicTrainingEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/academic-training/list',
          text: i18n.t('Routes.AcademicTrainingList'),
          active: false,
        },
        {
          to: '/apps/human-resources/academic-training/edit',
          text: i18n.t('Routes.AcademicTrainingEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/academic-training/AcademicTrainingAddEdit.vue'),
  },
  // *===============================================---*
  // *---------LaboralFunction-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/laboral-function/list',
    name: 'apps-human-resources-laboral-function-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LaboralFunctionList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/laboral-function/list',
          text: i18n.t('Routes.LaboralFunctionList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/laboral-function/LaboralFunctionList.vue'),
  },
  {
    path: '/apps/human-resources/laboral-function/add',
    name: 'apps-human-resources-laboral-function-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LaboralFunctionAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/laboral-function/list',
          text: i18n.t('Routes.LaboralFunctionList'),
          active: false,
        },
        {
          to: '/apps/human-resources/laboral-function/add',
          text: i18n.t('Routes.LaboralFunctionAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/laboral-function/LaboralFunctionAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/laboral-function/edit/:funcionLaboralId',
    name: 'apps-human-resources-laboral-function-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LaboralFunctionEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/laboral-function/list',
          text: i18n.t('Routes.LaboralFunctionList'),
          active: false,
        },
        {
          to: '/apps/human-resources/laboral-function/edit',
          text: i18n.t('Routes.LaboralFunctionEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/laboral-function/LaboralFunctionAddEdit.vue'),
  },
  // *===============================================---*
  // *---------Hability-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/hability/list',
    name: 'apps-human-resources-hability-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HabilityList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/hability/list',
          text: i18n.t('Routes.HabilityList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/hability/HabilityList.vue'),
  },
  {
    path: '/apps/human-resources/hability/add',
    name: 'apps-human-resources-hability-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HabilityAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/hability/list',
          text: i18n.t('Routes.HabilityList'),
          active: false,
        },
        {
          to: '/apps/human-resources/hability/add',
          text: i18n.t('Routes.HabilityAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/hability/HabilityAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/hability/edit/:habilidadId',
    name: 'apps-human-resources-hability-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HabilityEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/hability/list',
          text: i18n.t('Routes.HabilityList'),
          active: false,
        },
        {
          to: '/apps/human-resources/hability/edit',
          text: i18n.t('Routes.HabilityEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/hability/HabilityAddEdit.vue'),
  },
  // *===============================================---*
  // *---------HabilityType-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/hability-type/list',
    name: 'apps-human-resources-hability-type-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HabilityTypeList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/hability-type/list',
          text: i18n.t('Routes.HabilityTypeList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/hability-type/HabilityTypeList.vue'),
  },
  {
    path: '/apps/human-resources/hability-type/add',
    name: 'apps-human-resources-hability-type-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HabilityTypeAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/hability-type/list',
          text: i18n.t('Routes.HabilityTypeList'),
          active: false,
        },
        {
          to: '/apps/human-resources/hability-type/add',
          text: i18n.t('Routes.HabilityTypeAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/hability-type/HabilityTypeAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/hability-type/edit/:habilidadTipoId',
    name: 'apps-human-resources-hability-type-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HabilityTypeEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/hability-type/list',
          text: i18n.t('Routes.HabilityTypeList'),
          active: false,
        },
        {
          to: '/apps/human-resources/hability-type/edit',
          text: i18n.t('Routes.HabilityTypeEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/hability-type/HabilityTypeAddEdit.vue'),
  },
  // *===============================================---*
  // *---------Language-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/language/list',
    name: 'apps-human-resources-language-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LanguageList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/language/list',
          text: i18n.t('Routes.LanguageList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/language/LanguageList.vue'),
  },
  {
    path: '/apps/human-resources/language/add',
    name: 'apps-human-resources-language-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LanguageAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/language/list',
          text: i18n.t('Routes.LanguageList'),
          active: false,
        },
        {
          to: '/apps/human-resources/language/add',
          text: i18n.t('Routes.LanguageAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/language/LanguageAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/language/edit/:idiomaId',
    name: 'apps-human-resources-language-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.LanguageEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/language/list',
          text: i18n.t('Routes.LanguageList'),
          active: false,
        },
        {
          to: '/apps/human-resources/language/edit',
          text: i18n.t('Routes.LanguageEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/language/LanguageAddEdit.vue'),
  },
  // *===============================================---*
  // *---------Schedule-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/schedule/list',
    name: 'apps-human-resources-schedule-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ScheduleList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/schedule/list',
          text: i18n.t('Routes.ScheduleList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/schedule/ScheduleList.vue'),
  },
  {
    path: '/apps/human-resources/schedule/add',
    name: 'apps-human-resources-schedule-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ScheduleAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/schedule/list',
          text: i18n.t('Routes.ScheduleList'),
          active: false,
        },
        {
          to: '/apps/human-resources/schedule/add',
          text: i18n.t('Routes.ScheduleAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/schedule/ScheduleAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/schedule/edit/:horarioId',
    name: 'apps-human-resources-schedule-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ScheduleEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/schedule/list',
          text: i18n.t('Routes.ScheduleList'),
          active: false,
        },
        {
          to: '/apps/human-resources/schedule/edit',
          text: i18n.t('Routes.ScheduleEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/schedule/ScheduleAddEdit.vue'),
  },
  // *===============================================---*
  // *---------Profile-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/profile/list',
    name: 'apps-human-resources-profile-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile/list',
          text: i18n.t('Routes.ProfileList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile/ProfileList.vue'),
  },
  {
    path: '/apps/human-resources/profile/add',
    name: 'apps-human-resources-profile-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile/list',
          text: i18n.t('Routes.ProfileList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile/add',
          text: i18n.t('Routes.ProfileAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile/ProfileAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/profile/edit/:perfilId',
    name: 'apps-human-resources-profile-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile/list',
          text: i18n.t('Routes.ProfileList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile/edit',
          text: i18n.t('Routes.ProfileEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile/ProfileAddEdit.vue'),
  },
  // *===============================================---*
  // *---------ProfileAcademicTraining-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/profile-academic-training/list',
    name: 'apps-human-resources-profile-academic-training-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileAcademicTrainingList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-academic-training/list',
          text: i18n.t('Routes.ProfileAcademicTrainingList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-academic-training/ProfileAcademicTrainingList.vue'),
  },
  {
    path: '/apps/human-resources/profile-academic-training/add',
    name: 'apps-human-resources-profile-academic-training-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileAcademicTrainingAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-academic-training/list',
          text: i18n.t('Routes.ProfileAcademicTrainingList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-academic-training/add',
          text: i18n.t('Routes.ProfileAcademicTrainingAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-academic-training/ProfileAcademicTrainingAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/profile-academic-training/edit/:perfilFormacionAcademicaId',
    name: 'apps-human-resources-profile-academic-training-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileAcademicTrainingEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-academic-training/list',
          text: i18n.t('Routes.ProfileAcademicTrainingList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-academic-training/edit',
          text: i18n.t('Routes.ProfileAcademicTrainingEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-academic-training/ProfileAcademicTrainingAddEdit.vue'),
  },
  // *===============================================---*
  // *---------ProfileHability-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/profile-hability/list',
    name: 'apps-human-resources-profile-hability-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileHabilityList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-hability/list',
          text: i18n.t('Routes.ProfileHabilityList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-hability/ProfileHabilityList.vue'),
  },
  {
    path: '/apps/human-resources/profile-hability/add',
    name: 'apps-human-resources-profile-hability-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileHabilityAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-hability/list',
          text: i18n.t('Routes.ProfileHabilityList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-hability/add',
          text: i18n.t('Routes.ProfileHabilityAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-hability/ProfileHabilityAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/profile-hability/edit/:perfilHabilidadId',
    name: 'apps-human-resources-profile-hability-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileHabilityEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-hability/list',
          text: i18n.t('Routes.ProfileHabilityList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-hability/edit',
          text: i18n.t('Routes.ProfileHabilityEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-hability/ProfileHabilityAddEdit.vue'),
  },
  // *===============================================---*
  // *---------ProfileLanguage-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/profile-language/list',
    name: 'apps-human-resources-profile-language-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileLanguageList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-language/list',
          text: i18n.t('Routes.ProfileLanguageList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-language/ProfileLanguageList.vue'),
  },
  {
    path: '/apps/human-resources/profile-language/add',
    name: 'apps-human-resources-profile-language-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileLanguageAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-language/list',
          text: i18n.t('Routes.ProfileLanguageList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-language/add',
          text: i18n.t('Routes.ProfileLanguageAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-language/ProfileLanguageAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/profile-language/edit/:perfilIdiomaId',
    name: 'apps-human-resources-profile-language-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileLanguageEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-language/list',
          text: i18n.t('Routes.ProfileLanguageList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-language/edit',
          text: i18n.t('Routes.ProfileLanguageEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-language/ProfileLanguageAddEdit.vue'),
  },
  // *===============================================---*
  // *---------ProfileSalary-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/profile-salary/list',
    name: 'apps-human-resources-profile-salary-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileSalaryList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-salary/list',
          text: i18n.t('Routes.ProfileSalaryList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-salary/ProfileSalaryList.vue'),
  },
  {
    path: '/apps/human-resources/profile-salary/add',
    name: 'apps-human-resources-profile-salary-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileSalaryAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-salary/list',
          text: i18n.t('Routes.ProfileSalaryList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-salary/add',
          text: i18n.t('Routes.ProfileSalaryAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-salary/ProfileSalaryAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/profile-salary/edit/:perfilSalarialId',
    name: 'apps-human-resources-profile-salary-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.ProfileSalaryEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/profile-salary/list',
          text: i18n.t('Routes.ProfileSalaryList'),
          active: false,
        },
        {
          to: '/apps/human-resources/profile-salary/edit',
          text: i18n.t('Routes.ProfileSalaryEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/profile-salary/ProfileSalaryAddEdit.vue'),
  },
  // *===============================================---*
  // *---------PaymentTypePeriod-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/payment-type-period/list',
    name: 'apps-human-resources-payment-type-period-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.PaymentTypePeriodList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/payment-type-period/list',
          text: i18n.t('Routes.PaymentTypePeriodList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/payment-type-period/PaymentTypePeriodList.vue'),
  },
  {
    path: '/apps/human-resources/payment-type-period/add',
    name: 'apps-human-resources-payment-type-period-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.PaymentTypePeriodAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/payment-type-period/list',
          text: i18n.t('Routes.PaymentTypePeriodList'),
          active: false,
        },
        {
          to: '/apps/human-resources/payment-type-period/add',
          text: i18n.t('Routes.PaymentTypePeriodAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/payment-type-period/PaymentTypePeriodAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/payment-type-period/edit/:periodoPagoTipoId',
    name: 'apps-human-resources-payment-type-period-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.PaymentTypePeriodEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/payment-type-period/list',
          text: i18n.t('Routes.PaymentTypePeriodList'),
          active: false,
        },
        {
          to: '/apps/human-resources/payment-type-period/edit',
          text: i18n.t('Routes.PaymentTypePeriodEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/payment-type-period/PaymentTypePeriodAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- Job  -------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/job/list',
    name: 'apps-human-resources-job-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job/list',
          text: i18n.t('Routes.JobList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job/JobList.vue'),
  },
  {
    path: '/apps/human-resources/job/add',
    name: 'apps-human-resources-job-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job/list',
          text: i18n.t('Routes.JobList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job/add',
          text: i18n.t('Routes.JobAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job/JobAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/job/edit/:puestoId',
    name: 'apps-human-resources-job-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job/list',
          text: i18n.t('Routes.JobList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job/edit',
          text: i18n.t('Routes.JobEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job/JobAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- JobLaboralFunction  -------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/job-laboral-function/list',
    name: 'apps-human-resources-job-laboral-function-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobLaboralFunctionList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-laboral-function/list',
          text: i18n.t('Routes.JobLaboralFunctionList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-laboral-function/JobLaboralFunctionList.vue'),
  },
  {
    path: '/apps/human-resources/job-laboral-function/add',
    name: 'apps-human-resources-job-laboral-function-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobLaboralFunctionAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-laboral-function/list',
          text: i18n.t('Routes.JobLaboralFunctionList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job-laboral-function/add',
          text: i18n.t('Routes.JobLaboralFunctionAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-laboral-function/JobLaboralFunctionAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/job-laboral-function/edit/:puestoFuncionLaboralId',
    name: 'apps-human-resources-job-laboral-function-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobLaboralFunctionEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-laboral-function/list',
          text: i18n.t('Routes.JobLaboralFunctionList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job-laboral-function/edit',
          text: i18n.t('Routes.JobLaboralFunctionEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-laboral-function/JobLaboralFunctionAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- JobSchedule-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/job-schedule/list',
    name: 'apps-human-resources-job-schedule-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobScheduleList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-schedule/list',
          text: i18n.t('Routes.JobScheduleList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-schedule/JobScheduleList.vue'),
  },
  {
    path: '/apps/human-resources/job-schedule/add',
    name: 'apps-human-resources-job-schedule-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobScheduleAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-schedule/list',
          text: i18n.t('Routes.JobScheduleList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job-schedule/add',
          text: i18n.t('Routes.JobScheduleAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-schedule/JobScheduleAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/job-schedule/edit/:puestoHorarioId',
    name: 'apps-human-resources-job-schedule-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobScheduleEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-schedule/list',
          text: i18n.t('Routes.JobScheduleList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job-schedule/edit',
          text: i18n.t('Routes.JobScheduleEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-schedule/JobScheduleAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- JobLinePosition-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/human-resources/job-line-position/list',
    name: 'apps-human-resources-job-line-position-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobLinePositionList'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-line-position/list',
          text: i18n.t('Routes.JobLinePositionList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-line-position/JobLinePositionList.vue'),
  },
  {
    path: '/apps/human-resources/job-line-position/add',
    name: 'apps-human-resources-job-line-position-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobLinePositionAdd'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-line-position/list',
          text: i18n.t('Routes.JobLinePositionList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job-line-position/add',
          text: i18n.t('Routes.JobLinePositionAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-line-position/JobLinePositionAddEdit.vue'),
  },
  {
    path: '/apps/human-resources/job-line-position/edit/:puestoLineaAscensoId',
    name: 'apps-human-resources-job-line-position-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.JobLinePositionEdit'),
      breadcrumb: [
        {
          to: '/apps/human-resources/job-line-position/list',
          text: i18n.t('Routes.JobLinePositionList'),
          active: false,
        },
        {
          to: '/apps/human-resources/job-line-position/edit',
          text: i18n.t('Routes.JobLinePositionEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/human-resources/job-line-position/JobLinePositionAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- HierarchicalLevel-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/organization-chart/hierarchical-level/list',
    name: 'apps-organization-chart-hierarchical-level-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HierarchicalLevelList'),
      breadcrumb: [
        {
          to: '/apps/organization-chart/hierarchical-level/list',
          text: i18n.t('Routes.HierarchicalLevelList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/organization-chart/hierarchical-level/HierarchicalLevelList.vue'),
  },
  {
    path: '/apps/organization-chart/hierarchical-level/add',
    name: 'apps-organization-chart-hierarchical-level-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HierarchicalLevelAdd'),
      breadcrumb: [
        {
          to: '/apps/organization-chart/hierarchical-level/list',
          text: i18n.t('Routes.HierarchicalLevelList'),
          active: false,
        },
        {
          to: '/apps/organization-chart/hierarchical-level/add',
          text: i18n.t('Routes.HierarchicalLevelAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/organization-chart/hierarchical-level/HierarchicalLevelAddEdit.vue'),
  },
  {
    path: '/apps/organization-chart/hierarchical-level/edit/:nivelJerarquicoId',
    name: 'apps-organization-chart-hierarchical-level-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.HierarchicalLevelEdit'),
      breadcrumb: [
        {
          to: '/apps/organization-chart/hierarchical-level/list',
          text: i18n.t('Routes.HierarchicalLevelList'),
          active: false,
        },
        {
          to: '/apps/organization-chart/hierarchical-level/edit',
          text: i18n.t('Routes.HierarchicalLevelEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/organization-chart/hierarchical-level/HierarchicalLevelAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- Area-------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/organization-chart/area/list',
    name: 'apps-organization-chart-area-list',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.AreaList'),
      breadcrumb: [
        {
          to: '/apps/organization-chart/area/list',
          text: i18n.t('Routes.AreaList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/organization-chart/area/AreaList.vue'),
  },
  {
    path: '/apps/organization-chart/area/add',
    name: 'apps-organization-chart-area-add',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.AreaAdd'),
      breadcrumb: [
        {
          to: '/apps/organization-chart/area/list',
          text: i18n.t('Routes.AreaList'),
          active: false,
        },
        {
          to: '/apps/organization-chart/area/add',
          text: i18n.t('Routes.AreaAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/organization-chart/area/AreaAddEdit.vue'),
  },
  {
    path: '/apps/organization-chart/area/edit/:areaId',
    name: 'apps-organization-chart-area-edit',
    meta: {
      resource: 'RecursosHumanos',
      action: 'read',
      pageTitle: i18n.t('Routes.AreaEdit'),
      breadcrumb: [
        {
          to: '/apps/organization-chart/area/list',
          text: i18n.t('Routes.AreaList'),
          active: false,
        },
        {
          to: '/apps/organization-chart/area/edit',
          text: i18n.t('Routes.AreaEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/organization-chart/area/AreaAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- Customs  -------------------------------------------------------------*
  // *=================================================*
  {
    path: '/apps/custom/list',
    name: 'apps-custom-list',
    meta: {
      resource: 'GeneralDatos',
      action: 'read',
      pageTitle: i18n.t('Routes.customsList'),
      breadcrumb: [
        {
          resource: 'GeneralDatos',
          action: 'create',
          to: '/apps/aduana/list',
          text: i18n.t('Routes.customsList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/custom/CustomList.vue'),
  },
  {
    path: '/apps/custom/edit',
    name: 'apps-custom-edit',
    meta: {
      resource: 'GeneralDatos',
      action: 'write',
      pageTitle: i18n.t('Routes.editCustom'),
      breadcrumb: [
        {
          to: '/apps/custom/list',
          text: i18n.t('Routes.customsList'),
          active: false,
        },
        {
          text: i18n.t('Routes.editCustom'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/custom/CustomEdit.vue'),
  },
  // *===============================================---*
  // *--------- BondenWarehouse --------------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/bonden-warehouse/list',
    name: 'apps-bonden-warehouse-list',
    meta: {
      resource: 'GeneralDatos',
      action: 'read',
      pageTitle: i18n.t('Routes.bondenwarehouseList'),
      breadcrumb: [
        {
          to: '/apps/bonden-warehouse/list',
          text: i18n.t('Routes.bondenwarehouseList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/bonden-warehouse/BondenWarehouseList.vue'),
  },
  {
    path: '/apps/bonden-warehouse/edit',
    name: 'apps-bonden-warehouse-edit',
    meta: {
      resource: 'GeneralDatos',
      action: 'read',
      pageTitle: i18n.t('Routes.editBondenwarehouse'),
      breadcrumb: [
        {
          to: '/apps/bonden-warehouse/list',
          text: i18n.t('Routes.bondenwarehouseList'),
          active: false,
        },
        {
          text: i18n.t('Routes.editBondenwarehouse'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/bonden-warehouse/BondenWarehouseEdit.vue'),
  },
  // *===============================================---*
  // *--------- TransportationLines------------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/transportation-lines/list',
    name: 'apps-transportation-lines-list',
    meta: {
      resource: 'GeneralDatos',
      action: 'read',
      pageTitle: i18n.t('Routes.trasportationList'),
      breadcrumb: [
        {
          to: '/apps/transportation-lines/list',
          text: i18n.t('Routes.trasportationList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/transportation-lines/TransportationLinesList.vue'),
  },
  {
    path: '/apps/transportation-lines/edit',
    name: 'apps-transportation-lines-edit',
    meta: {
      resource: 'GeneralDatos',
      action: 'read',
      pageTitle: i18n.t('Routes.trasportationList'),
      breadcrumb: [
        {
          to: '/apps/transportation-lines/list',
          text: i18n.t('Routes.trasportationList'),
          active: false,
        },
        {
          text: i18n.t('Routes.editTrasportation'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/transportation-lines/TransportationLinesEdit.vue'),
  },
  // *===============================================---*
  // *===============================================---*
  // *--------- Bulletins-------------------------------*
  // *===============================================---*
  {
    path: '/apps/bulletin/list',
    name: 'apps-bulletins-list',
    meta: {
      resource: 'Boletines',
      action: 'read',
      pageTitle: i18n.t('Routes.bulletinList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.bulletinList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/bulletin/BulletinList.vue'),
  },
  {
    path: '/apps/bulletin/details',
    name: 'apps-bulletin-details',
    meta: {
      resource: 'Boletines',
      action: 'read',
      pageTitle: i18n.t('Routes.bulletinList'),
      breadcrumb: [
        {
          to: '/apps/bulletin/list',
          text: i18n.t('Routes.bulletinList'),
          active: false,
        },
        {
          text: i18n.t('Routes.bulletinDetails'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/bulletin/BulletinDetails.vue'),
  },

  // *===============================================---*
  // *===============================================---*
  // *--------- Clasifications -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/classification/list',
    name: 'apps-classification-list',
    meta: {
      resource: 'Clasificaciones',
      action: 'read',
      pageTitle: i18n.t('Routes.classificationList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.classificationList'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/classification/List.vue'),
  },
  {
    path: '/apps/classification/edit/:solicitudId',
    name: 'apps-classification-edit',
    meta: {
      resource: 'Clasificaciones',
      action: 'read',
      pageTitle: i18n.t('Routes.classification'),
      breadcrumb: [
        {
          text: i18n.t('Routes.classificationList'),
          active: false,
          to: '/apps/classification/list',
        },
        {
          text: i18n.t('Routes.classification'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/classification/Edit.vue'),
  },
  // *===============================================---*
  // *--------- Confronts ---- ---------------------------------------*
  // *===============================================---*
  // Warehouse
  {
    path: '/apps/confronts/warehouse/list',
    name: 'apps-confronts-warehouse-list',
    meta: {
      resource: 'Confrontas',
      action: 'read',
      pageTitle: i18n.t('Routes.confronsSearch'),
      breadcrumb: [
        {
          text: i18n.t('Routes.confronsSearch'),
          active: false,
          to: '/apps/confronts/warehouse/list',
        },
      ],
    },
    component: () => import('@/views/apps/confront/warehouse/ConfrontWarehouseList.vue'),
  },
  {
    path: '/apps/confronts/warehouse/check',
    name: 'apps-confronts-warehouse-check',
    meta: {
      resource: 'Confrontas',
      action: 'read',
      pageTitle: i18n.t('Routes.newConfront'),
      breadcrumb: [
        {
          text: i18n.t('Routes.confronsSearch'),
          active: false,
          to: '/apps/confronts/warehouse/list',
        },
        {
          text: i18n.t('Routes.newConfront'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/confront/warehouse/ConfrontWarehouseCheck.vue'),
  },
  // Load
  {
    path: '/apps/confronts/load/list',
    name: 'apps-confronts-load-list',
    meta: {
      resource: 'Confrontas',
      action: 'read',
      pageTitle: i18n.t('Routes.loadPending'),
      breadcrumb: [
        {
          text: i18n.t('Routes.loadPending'),
          active: false,
          to: '/apps/confronts/load/list',
        },
      ],
    },
    component: () => import('@/views/apps/confront/load/ConfrontLoadList.vue'),
  },
  {
    path: '/apps/confronts/load/check',
    name: 'apps-confronts-load-check',
    meta: {
      resource: 'Confrontas',
      action: 'read',
      pageTitle: i18n.t('Routes.newLoad'),
      breadcrumb: [
        {
          text: i18n.t('Routes.loadPending'),
          active: false,
          to: '/apps/confronts/load/list',
        },
        {
          text: i18n.t('Routes.newLoad'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/confront/load/ConfrontLoadCheck.vue'),
  },
  // *===============================================---*
  // *--------- Customers ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/customers/list',
    name: 'apps-customers-list',
    meta: {
      resource: 'Clientes',
      action: 'read',
      pageTitle: i18n.t('Routes.customersList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.customersList'),
          active: true,
          to: '/apps/customers/list',
        },
      ],
    },
    component: () => import('@/views/apps/customer/customers-list/CustomersList.vue'),
  },
  {
    path: '/apps/customers/edit/:clienteId',
    name: 'apps-customers-edit',
    meta: {
      resource: 'Clientes',
      action: 'write',
      pageTitle: i18n.t('Routes.editCustomer'),
      breadcrumb: [
        {
          text: i18n.t('Routes.customersList'),
          active: false,
          to: '/apps/customers/list',
        },
        {
          text: i18n.t('Routes.editCustomer'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/customer/customers-edit/CustomerEdit.vue'),
  },
  // *===============================================---*
  // *--------- Customer Recipients ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/customers/edit/:clienteId/recipient/new',
    name: 'apps-recipients-new',
    meta: {
      resource: 'Clientes',
      action: 'write',
      pageTitle: i18n.t('AppCustomer.CustomerRecipients.newRecipients'),
      breadcrumb: [
        {
          text: i18n.t('Routes.customersList'),
          active: false,
          to: '/apps/customers/list',
        },
        {
          text: i18n.t('AppCustomer.CustomerRecipients.newRecipients'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/customer/customers-edit/recipient/RecipientAddNew.vue'),
  },
  {
    path: '/apps/customers/edit/:clienteId/recipient/edit/:clienteDestinatarioExpedienteDigitalId',
    name: 'apps-recipients-edit',
    meta: {
      resource: 'Clientes',
      action: 'write',
      pageTitle: i18n.t('AppCustomer.CustomerRecipients.editRecipients'),
      breadcrumb: [
        {
          text: i18n.t('Routes.customersList'),
          active: false,
          to: '/apps/customers/list',
        },
        {
          text: i18n.t('AppCustomer.CustomerRecipients.editRecipients'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/customer/customers-edit/recipient/RecipientEdit.vue'),
  },
  // *===============================================---*
  // *--------- DigitalRecords ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/digitalrecord/query',
    name: 'apps-digtalrecord-query',
    meta: {
      pageTitle: i18n.t('Routes.queryDigitalFile'),
      resource: 'ExpedientesDigitales',
      action: 'read',
      breadcrumb: [
        {
          text: i18n.t('Routes.queryDigitalFile'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/digital-record/digital-record-query/DigitalRecordQuery.vue'),
  },
  {
    path: '/apps/digitalrecord/downloads',
    name: 'apps-digtalrecord-downloads',
    meta: {
      resource: 'ExpedientesDigitales',
      action: 'read',
    },
    component: () => import('@/views/apps/digital-record/digital-record-downloads/DigitalRecordDownloads.vue'),
  },
  {
    path: '/apps/digitalrecord/management',
    name: 'apps-digtalrecord-management',
    meta: {
      resource: 'ExpedientesDigitales',
      action: 'write',
      pageTitle: i18n.t('Routes.digitalRecordsList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.digitalRecordsList'),
          active: false,
          to: '/apps/digitalrecord/management',
        },
      ],
    },
    component: () => import('@/views/apps/digital-record/digital-record-management/DigitalRecordManagementList.vue'),
  },
  {
    path: '/apps/digitalrecord/management/AddEddit',
    name: 'apps-digtalrecord-management-addedit',
    meta: {
      resource: 'ExpedientesDigitales',
      action: 'write',
      pageTitle: i18n.t('Routes.digitalRecordEdit'),
      breadcrumb: [
        {
          text: i18n.t('Routes.digitalRecordsList'),
          active: false,
          to: '/apps/digitalrecord/management',
        },
        {
          text: i18n.t('Routes.digitalRecordEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/digital-record/digital-record-management/DigitalRecordManagementAddEdit.vue'),
  },
  {
    path: '/apps/digitalrecord/settings',
    name: 'apps-digtalrecord-settings',
    meta: {
      resource: 'ExpedientesDigitales',
      action: 'write',
    },
    component: () => import('@/views/apps/digital-record/digital-record-settings/DigitalRecordSettings.vue'),
  },
  // *===============================================---*
  // *--------- Parts ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/parts/list',
    name: 'apps-parts-list',
    meta: {
      resource: 'Partes',
      action: 'read',
      pageTitle: i18n.t('Routes.partsList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.partsList'),
          active: true,
          to: '/apps/parts/list',
        },
      ],
    },
    component: () => import('@/views/apps/part/parts-list/PartsList.vue'),
  },
  {
    path: '/apps/parts/edit/:parteId',
    name: 'apps-parts-edit',
    meta: {
      resource: 'Partes',
      action: 'write',
      pageTitle: i18n.t('Routes.editPart'),
      breadcrumb: [
        {
          text: i18n.t('Routes.partsList'),
          active: false,
          to: '/apps/parts/list',
        },
        {
          text: i18n.t('Routes.editPart'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/part/parts-edit/PartEdit.vue'),
  },
  {
    path: '/apps/parts/log/:parteId',
    name: 'apps-parts-log',
    meta: {
      resource: 'Partes',
      action: 'read',
      pageTitle: i18n.t('Routes.editPart'),
      breadcrumb: [
        {
          text: i18n.t('Routes.partsList'),
          active: false,
          to: '/apps/parts/list',
        },
        {
          text: i18n.t('Routes.partLog'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/part/parts-list/PartListLog.vue'),
  },
  // *===============================================---*
  // *--------- Previous ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/previous/list',
    name: 'apps-previous-list',
    meta: {
      resource: 'Previos',
      action: 'read',
      pageTitle: i18n.t('Routes.previousList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.previousList'),
          active: true,
          to: '/apps/previous/list',
        },
      ],
    },
    component: () => import('@/views/apps/previous/PreviousList.vue'),
  },
  {
    path: '/apps/previous/list/add',
    name: 'apps-previous-add',
    meta: {
      resource: 'Previos',
      action: 'read',
      pageTitle: i18n.t('Routes.addPrevious'),
      breadcrumb: [
        {
          text: i18n.t('Routes.previousList'),
          active: false,
          to: '/apps/previous/list',
        },
        {
          text: i18n.t('Routes.addPrevious'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/previous/PreviousAddEdit.vue'),
  },
  {
    path: '/apps/previous/edit/:guid',
    name: 'apps-previous-edit',
    meta: {
      resource: 'Previos',
      action: 'write',
      pageTitle: i18n.t('Routes.editPrevious'),
      breadcrumb: [
        {
          text: i18n.t('Routes.previousList'),
          active: false,
          to: '/apps/previous/list',
        },
        {
          text: i18n.t('Routes.editPrevious'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/previous/PreviousAddEdit.vue'),
  },
  {
    path: '/apps/previous/viewer/:guid',
    name: 'apps-previous-viewer',
    meta: {
      resource: 'Previos',
      action: 'read',
      pageTitle: i18n.t('Routes.previousViewer'),
      breadcrumb: [
        {
          text: i18n.t('Routes.previousList'),
          active: false,
          to: '/apps/previous/list',
        },
        {
          text: i18n.t('Routes.previousViewer'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/previous/PreviousViewer.vue'),
  },
  {
    path: '/apps/previous/observations/:previoId',
    name: 'apps-previous-observations',
    meta: {
      resource: 'Previos',
      action: 'read',
      pageTitle: i18n.t('Routes.previousObservations'),
      breadcrumb: [
        {
          text: i18n.t('Routes.previousList'),
          active: false,
          to: '/apps/previous/list',
        },
        {
          text: i18n.t('Routes.previousObservations'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/previous/PreviousObservations.vue'),
  },
  // *==============================================---*
  // *--------- Receivers ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/receivers/list',
    name: 'apps-receivers-list',
    meta: {
      resource: 'Destinatarios',
      action: 'read',
      pageTitle: i18n.t('Routes.recieversList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.recieversList'),
          active: true,
          to: '/apps/parts/list',
        },
      ],
    },
    component: () => import('@/views/apps/receiver/receivers-list/ReceiversList.vue'),
  },
  {
    path: '/apps/receivers/edit/:destinatarioId',
    name: 'apps-receivers-edit',
    meta: {
      resource: 'Destinatarios',
      action: 'write',
      pageTitle: i18n.t('Routes.editReciever'),
      breadcrumb: [
        {
          text: i18n.t('Routes.recieversList'),
          active: false,
          to: '/apps/receivers/list',
        },
        {
          text: i18n.t('Routes.editReciever'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/receiver/receivers-edit/ReceiverEdit.vue'),
  },
  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    meta: {
      resource: 'Usuarios',
      action: 'read',
      pageTitle: i18n.t('Routes.usersList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.usersList'),
          active: true,
          to: '/apps/users/list',
        },
      ],
    },
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/edit/:userName',
    name: 'apps-users-edit',
    meta: {
      resource: 'Usuarios',
      action: 'write',
      pageTitle: i18n.t('Routes.editUser'),
      breadcrumb: [
        {
          text: i18n.t('Routes.usersList'),
          active: false,
          to: '/apps/users/list',
        },
        {
          text: i18n.t('Routes.editUser'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // *===============================================---*
  // *--------- Vendors ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/vendors/list',
    name: 'apps-vendors-list',
    meta: {
      resource: 'Proveedores',
      action: 'read',
      pageTitle: i18n.t('Routes.vendorsList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.vendorsList'),
          active: true,
          to: '/apps/vendors/list',
        },
      ],
    },
    component: () => import('@/views/apps/vendor/vendors-list/VendorsList.vue'),
  },
  {
    path: '/apps/vendors/edit/:proveedorId',
    name: 'apps-vendors-edit',
    meta: {
      resource: 'Proveedores',
      action: 'write',
      pageTitle: i18n.t('Routes.editVendor'),
      breadcrumb: [
        {
          text: i18n.t('Routes.vendorsList'),
          active: false,
          to: '/apps/vendors/list',
        },
        {
          text: i18n.t('Routes.editVendor'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/vendor/vendors-edit/VendorEdit.vue'),
  },
  {
    path: '/apps/vendors/ship/list',
    name: 'apps-vendors-ship-list',
    meta: {
      resource: 'Proveedores',
      action: 'write',
    },
    component: () => import('@/views/apps/vendor/vendors-ship/VendorShip.vue'),
  },

  // *===============================================---*
  // *---------Quality & Security ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/quality/soc',
    name: 'apps-quality-soc',
    meta: {
      resource: 'Proveedores',
      action: 'write',
    },
    component: () => import('@/views/apps/quality/quality-repository.vue'),
  },

  // *===============================================---*
  // *--------- Tickets ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/tickets/list',
    name: 'apps-tickets-list',
    meta: {
      resource: 'MesaAyuda',
      action: 'read',
      pageTitle: i18n.t('Routes.TicketsList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketsList'),
          active: true,
          to: '/apps/tickets/list',
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/tickets-list/TicketList.vue'),
  },
  {
    path: '/apps/tickets/log/:ticketId',
    name: 'apps-tickets-log',
    meta: {
      resource: 'MesaAyuda',
      action: 'read',
      pageTitle: i18n.t('Routes.ticketLog'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketsList'),
          active: false,
          to: '/apps/tickets/list',
        },
        {
          text: i18n.t('Routes.ticketLog'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/tickets-list/TicketListLog.vue'),
  },
  {
    path: '/apps/tickets/add',
    name: 'apps-tickets-add',
    meta: {
      resource: 'MesaAyuda',
      action: 'read',
      pageTitle: i18n.t('Routes.addTicket'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketsList'),
          active: false,
          to: '/apps/tickets/list',
        },
        {
          text: i18n.t('Routes.addTicket'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/tickets-list/TicketListAddNew.vue'),
  },
  {
    path: '/apps/tickets/edit/:ticketId',
    name: 'apps-tickets-edit',
    meta: {
      resource: 'MesaAyuda',
      action: 'read',
      pageTitle: i18n.t('Routes.editTicket'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketsList'),
          active: false,
          to: '/apps/tickets/list',
        },
        {
          text: i18n.t('Routes.editTicket'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/tickets-edit/TicketEdit.vue'),
  },
  // *===============================================---*
  // *--------- Categories Tickets ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/category/list',
    name: 'apps-categories-list',
    meta: {
      resource: 'Categories',
      action: 'read',
      pageTitle: i18n.t('Routes.CategoriesList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.CategoriesList'),
          active: true,
          to: '/apps/category/list',
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/category-list/CategoryList.vue'),
  },
  {
    path: '/apps/category/edit/:ticketCategoriaId',
    name: 'apps-categories-edit',
    meta: {
      resource: 'Categories',
      action: 'write',
      pageTitle: i18n.t('Routes.editCategory'),
      breadcrumb: [
        {
          text: i18n.t('Routes.CategoriesList'),
          to: '/apps/category/list',
        },
        {
          text: i18n.t('Routes.editCategory'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/category-list/CategoryEdit.vue'),
  },
  {
    path: '/apps/category/new',
    name: 'apps-categories-new',
    meta: {
      resource: 'Categories',
      action: 'create',
      pageTitle: i18n.t('Routes.addCategory'),
      breadcrumb: [
        {
          text: i18n.t('Routes.CategoriesList'),
          to: '/apps/category/list',
        },
        {
          text: i18n.t('Routes.addCategory'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/category-list/CategoryNew.vue'),
  },
  {
    path: '/apps/category/users/:ticketCategoriaId',
    name: 'apps-categories-users',
    meta: {
      resource: 'Categories',
      action: 'create',
      pageTitle: i18n.t('Routes.userCategory'),
      breadcrumb: [
        {
          text: i18n.t('Routes.CategoriesList'),
          to: '/apps/category/list',
        },
        {
          text: i18n.t('Routes.userCategory'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/category-list/CategoryUsers.vue'),
  },
  // *===============================================---*
  // *--------- Usuarios activos ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/tickets/users/list',
    name: 'apps-tickets-user-list',
    meta: {
      resource: 'Users',
      action: 'read',
      pageTitle: i18n.t('Routes.TicketUserList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketUserList'),
          active: true,
          to: '/apps/tickets/users/list',
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/users-active/UserList.vue'),
  },
  {
    path: '/apps/tickets/users/list/add',
    name: 'apps-tickets-user-list-add',
    meta: {
      resource: 'Users',
      action: 'read',
      pageTitle: i18n.t('Routes.TicketUserList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketUserList'),
          to: '/apps/tickets/users/list',
        },
        {
          text: i18n.t('Routes.TicketUserAdd'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/users-active/UserListAdd.vue'),
  },
  {
    path: '/apps/tickets/users/list/edit/:usuarioActivoId',
    name: 'apps-tickets-user-list-edit',
    meta: {
      resource: 'Users',
      action: 'read',
      pageTitle: i18n.t('Routes.TicketUserEdit'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketUserList'),
          to: '/apps/tickets/users/list',
        },
        {
          text: i18n.t('Routes.TicketUserEdit'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/users-active/UserListEdit.vue'),
  },
  {
    path: '/apps/tickets/users/list/contact/:usuarioActivoId',
    name: 'apps-tickets-user-list-contact',
    meta: {
      resource: 'Users',
      action: 'read',
      pageTitle: i18n.t('Routes.TicketUserList'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TicketUserList'),
          to: '/apps/tickets/users/list',
        },
        {
          text: i18n.t('AppHelpDesk.users.contact'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/users-active/UserListContact.vue'),
  },
  // *===============================================---*
  // *--------- Incidencias -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/tickets/incidents/list',
    name: 'apps-tickets-incidents-list',
    meta: {
      resource: 'Incidencias',
      action: 'read',
      pageTitle: i18n.t('Routes.Incidents'),
      breadcrumb: [
        {
          text: i18n.t('Routes.Incidents'),
          active: true,
          to: '/apps/tickets/incidents/list',
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/incidents-list/IncidentsList.vue'),
  },
  {
    path: '/apps/tickets/incidents/list/add',
    name: 'apps-tickets-incidents-add',
    meta: {
      resource: 'Incidencias',
      action: 'write',
      pageTitle: i18n.t('AppHelpDesk.newIncident'),
      breadcrumb: [
        {
          text: i18n.t('Routes.Incidents'),
          to: '/apps/tickets/incidents/list',
        },
        {
          text: i18n.t('AppHelpDesk.newIncident'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/incidents-list/IncidentsListAdd.vue'),
  },
  {
    path: '/apps/tickets/incidents/list/edit/:incidenciaId',
    name: 'apps-tickets-incidents-edit',
    meta: {
      resource: 'Incidencias',
      action: 'write',
      pageTitle: i18n.t('AppHelpDesk.editIncident'),
      breadcrumb: [
        {
          text: i18n.t('Routes.Incidents'),
          to: '/apps/tickets/incidents/list',
        },
        {
          text: i18n.t('AppHelpDesk.editIncident'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/help-desk/incidents-list/IncidentsListEdit.vue'),
  },
  // *===============================================---*
  // *--------- Security -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/security/matriz-permisos',
    name: 'apps-security-permissions-matrix',
    meta: {
      resource: 'Seguridad',
      action: 'read',
      pageTitle: i18n.t('PermissionsMatrix'),
      breadcrumb: [
        {
          text: i18n.t('PermissionsMatrix'),
          active: true,
          to: '/apps/security/matriz-permisos',
        },
      ],
    },
    component: () => import('@/views/apps/security/permission-matrix-downloads/PermissionMatrixDownloads.vue'),
  },
  // *===============================================---*
  // *--------- TI -------------------------------------------*
  // *===============================================---*
  {
    path: '/apps/ti/software/list',
    name: 'apps-software-list',
    meta: {
      resource: 'Software',
      action: 'read',
      pageTitle: i18n.t('Routes.Softwares'),
      breadcrumb: [
        {
          text: i18n.t('Routes.Softwares'),
          active: true,
          to: '/apps/ti/software/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/software-list/SoftwareList.vue'),
  },
  {
    path: '/apps/ti/software/details/:softwareId',
    name: 'apps-software-details',
    meta: {
      resource: 'Software',
      action: 'read',
      pageTitle: i18n.t('Routes.Softwares'),
      breadcrumb: [
        {
          text: i18n.t('Routes.Softwares'),
          active: false,
          to: '/apps/ti/software/list',
        },
        {
          text: i18n.t('AppTI.software.details'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/software-edit/SoftwareTabs.vue'),
  },
  {
    path: '/apps/ti/software/add',
    name: 'apps-software-add',
    meta: {
      resource: 'Software',
      action: 'create',
      pageTitle: i18n.t('Routes.Softwares'),
      breadcrumb: [
        {
          text: i18n.t('Routes.Softwares'),
          to: '/apps/ti/software/list',
          active: false,
        },
        {
          text: i18n.t('Routes.addSoftware'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/software-list/SoftwareAdd.vue'),
  },
  {
    path: '/apps/ti/provider/list',
    name: 'apps-provider-list',
    meta: {
      resource: 'Provedorees',
      action: 'read',
      pageTitle: i18n.t('Routes.providers'),
      breadcrumb: [
        {
          text: i18n.t('Routes.providers'),
          active: true,
          to: '/apps/ti/provider/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/provider-list/ProviderList.vue'),
  },
  {
    path: '/apps/ti/provider/list/new',
    name: 'apps-provider-new',
    meta: {
      resource: 'Provedorees',
      action: 'create',
      pageTitle: i18n.t('AppTI.actions.newProvider'),
      breadcrumb: [
        {
          text: i18n.t('Routes.providers'),
          active: false,
          to: '/apps/ti/provider/list',
        },
        {
          text: i18n.t('AppTI.actions.newProvider'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/provider-list/ProviderListNew.vue'),
  },
  {
    path: '/apps/ti/provider/list/details/:proveedorTiId',
    name: 'apps-provider-details',
    meta: {
      resource: 'Provedorees',
      action: 'edit',
      pageTitle: i18n.t('Routes.editProvider'),
      breadcrumb: [
        {
          text: i18n.t('Routes.providers'),
          active: false,
          to: '/apps/ti/provider/list',
        },
        {
          text: i18n.t('AppTI.provider.details'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/provider-edit/ProviderTabs.vue'),
  },
  {
    path: '/apps/ti/provider/list/details/:proveedorTiId/contact/new',
    name: 'apps-provider-contact-new',
    meta: {
      resource: 'Provedores',
      action: 'create',
      pageTitle: i18n.t('Routes.newContact'),
      breadcrumb: [
        {
          text: i18n.t('Routes.providers'),
          active: false,
          to: '/apps/ti/provider/list',
        },
        {
          text: i18n.t('AppTI.actions.newContact'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/provider-edit/ProviderContactAddEdit.vue'),
  },
  {
    path: '/apps/ti/provider/list/details/:proveedorTiId/contact/edit/:proveedorTiContactoId',
    name: 'apps-provider-contact-edit',
    meta: {
      resource: 'Provedores',
      action: 'edit',
      pageTitle: i18n.t('Routes.editContact'),
      breadcrumb: [
        {
          text: i18n.t('Routes.providers'),
          active: false,
          to: '/apps/ti/provider/list',
        },
        {
          text: i18n.t('Routes.editContact'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/provider-edit/ProviderContactAddEdit.vue'),
  },
  {
    path: '/apps/ti/typeequipment/list',
    name: 'apps-type-equipment-list',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.TypeEquipments'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TypeEquipments'),
          active: true,
          to: '/apps/ti/typeequipment/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/type-equipment-list/TypeEquipmentList.vue'),
  },
  {
    path: '/apps/ti/typeequipment/add',
    name: 'apps-type-equipment-add',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.TypeEquipments'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TypeEquipments'),
          active: false,
          to: '/apps/ti/typeequipment/list',
        },
        {
          text: i18n.t('Routes.addTypeEquipment'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/type-equipment-list/TypeEquipmentAdd.vue'),
  },
  {
    path: '/apps/ti/typeequipment/details/:tipoEquipoId',
    name: 'apps-type-equipment-details',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.TypeEquipments'),
      breadcrumb: [
        {
          text: i18n.t('Routes.TypeEquipments'),
          active: false,
          to: '/apps/ti/typeequipment/list',
        },
        {
          text: i18n.t('Routes.detailsTypeEquipment'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/type-equipment-edit/EquipmentTabs.vue'),
  },
  {
    path: '/apps/ti/brands/list',
    name: 'apps-brands-list',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.listBrands'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBrands'),
          active: true,
          to: '/apps/ti/brands/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/brands-list/BrandsList.vue'),
  },
  {
    path: '/apps/ti/brands/add',
    name: 'apps-brands-add',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.addNewBrand'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBrands'),
          active: false,
          to: '/apps/ti/brands/list',
        },
        {
          text: i18n.t('Routes.addNewBrand'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/brands-list/BrandAdd.vue'),
  },
  {
    path: '/apps/ti/brands/edit/:tipoMarcaId',
    name: 'apps-brands-edit',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.editBrand'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBrands'),
          active: false,
          to: '/apps/ti/brands/list',
        },
        {
          text: i18n.t('Routes.editBrand'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/brands-list/BrandEdit.vue'),
  },
  {
    path: '/apps/ti/brands/list/details/:tipoMarcaId',
    name: 'apps-brands-details',
    meta: {
      resource: 'Marcas',
      action: 'edit',
      pageTitle: i18n.t('Routes.listBrands'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBrands'),
          active: false,
          to: '/apps/ti/brands/list',
        },
        {
          text: i18n.t('AppTI.brand.details'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/brands-list/BrandTabs.vue'),
  },
  {
    path: '/apps/ti/model/list',
    name: 'apps-model-list',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.listModels'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listModels'),
          active: true,
          to: '/apps/ti/model/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/model-list/ModelsList.vue'),
  },
  {
    path: '/apps/ti/model/add',
    name: 'apps-model-add',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.addModel'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listModels'),
          active: false,
          to: '/apps/ti/model/list',
        },
        {
          text: i18n.t('Routes.addModel'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/model-list/ModelAdd.vue'),
  },
  {
    path: '/apps/ti/model/edit/:tipoEquipoMarcaModeloId',
    name: 'apps-model-edit',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.editModel'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listModels'),
          active: false,
          to: '/apps/ti/model/list',
        },
        {
          text: i18n.t('Routes.editModel'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/model-list/ModelEdit.vue'),
  },
  {
    path: '/apps/ti/equipment/list',
    name: 'apps-equipment-list',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.equipments'),
      breadcrumb: [
        {
          text: i18n.t('Routes.equipments'),
          active: true,
          to: '/apps/ti/equipment/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/equipment-list/EquipmentList.vue'),
  },
  {
    path: '/apps/ti/equipment/add',
    name: 'apps-equipment-add',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.newEquipment'),
      breadcrumb: [
        {
          text: i18n.t('Routes.equipments'),
          active: false,
          to: '/apps/ti/equipment/list',
        },
        {
          text: i18n.t('Routes.newEquipment'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/equipment-list/EquipmentAdd.vue'),
  },
  {
    path: '/apps/ti/equipment/details/:equipoId',
    name: 'apps-equipment-details',
    meta: {
      resource: 'TipoEquipo',
      action: 'read',
      pageTitle: i18n.t('Routes.detailsEquipment'),
      breadcrumb: [
        {
          text: i18n.t('Routes.equipments'),
          active: false,
          to: '/apps/ti/equipment/list',
        },
        {
          text: i18n.t('Routes.detailsEquipment'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/equipment-edit/EquipmentTabs.vue'),
  },
  {
    path: '/apps/ti/account-bank/list',
    name: 'apps-account-bank-list',
    meta: {
      resource: 'CuentaBancaria',
      action: 'read',
      pageTitle: i18n.t('Routes.listAccountBank'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listAccountBank'),
          active: true,
          to: '/apps/ti/account-bank/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/account-bank-list/AccountBankList.vue'),
  },
  {
    path: '/apps/ti/account-bank/edit/:proveedorTiCuentaBancariaId',
    name: 'apps-account-bank-edit',
    meta: {
      resource: 'CuentaBancaria',
      action: 'write',
      pageTitle: i18n.t('Routes.EditAccountBank'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listAccountBank'),
          active: false,
          to: '/apps/ti/account-bank/list',
        },
        {
          text: i18n.t('Routes.EditAccountBank'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/account-bank-list/AccountBankAddEdit.vue'),
  },
  {
    path: '/apps/ti/account-bank/add',
    name: 'apps-account-bank-add',
    meta: {
      resource: 'CuentaBancaria',
      action: 'write',
      pageTitle: i18n.t('Routes.AddAccountBank'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listAccountBank'),
          active: false,
          to: '/apps/ti/account-bank/list',
        },
        {
          text: i18n.t('Routes.AddAccountBank'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/account-bank-list/AccountBankAddEdit.vue'),
  },
  {
    path: '/apps/ti/bank/list',
    name: 'apps-bank-list',
    meta: {
      resource: 'Banco',
      action: 'read',
      pageTitle: i18n.t('Routes.listBank'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBank'),
          active: true,
          to: '/apps/ti/bank/list',
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/bank/BankList.vue'),
  },
  {
    path: '/apps/ti/bank/edit/:bancoId',
    name: 'apps-bank-edit',
    meta: {
      resource: 'CuentaBancaria',
      action: 'write',
      pageTitle: i18n.t('Routes.EditBank'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBank'),
          active: false,
          to: '/apps/ti/bank/list',
        },
        {
          text: i18n.t('Routes.EditBank'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/bank/BankAddEdit.vue'),
  },
  {
    path: '/apps/ti/bank/add',
    name: 'apps-bank-add',
    meta: {
      resource: 'CuentaBancaria',
      action: 'write',
      pageTitle: i18n.t('Routes.AddBank'),
      breadcrumb: [
        {
          text: i18n.t('Routes.listBank'),
          active: false,
          to: '/apps/ti/bank/list',
        },
        {
          text: i18n.t('Routes.AddBank'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/apps/information-tecnology/bank/BankAddEdit.vue'),
  },
  // *===============================================---*
  // *--------- Transfers ------------------------------*
  // *===============================================---*
  {
    path: '/apps/transfers/mbge/pedimento/list',
    name: 'apps-transfers-mbge-pedimento-list',
    meta: {
      // resource: '',
      // action: '',
      pageTitle: 'Pedimentos pendientes', // i18n.t('Routes.AddBank'),
      breadcrumb: [
        // {
        //   text: i18n.t('Routes.listBank'),
        //   active: false,
        //   to: '/apps/ti/bank/list',
        // },
        // {
        //   text: i18n.t('Routes.AddBank'),
        //   active: true,
        // },
      ],
    },
    component: () => import('@/views/apps/transfers/mbge/PedimentosList.vue'),
  },
  {
    path: '/apps/transfers/mbge/pedimento/loaddocument',
    name: 'apps-transfers-mbge-pedimento-load-document',
    meta: {
      // resource: '',
      // action: '',
      pageTitle: 'Pedimento carga documento', // i18n.t('Routes.AddBank'),
      breadcrumb: [
        // {
        //   text: i18n.t('Routes.listBank'),
        //   active: false,
        //   to: '/apps/ti/bank/list',
        // },
        // {
        //   text: i18n.t('Routes.AddBank'),
        //   active: true,
        // },
      ],
    },
    component: () => import('@/views/apps/transfers/mbge/PedimentosLoadDocument.vue'),
  },
]
