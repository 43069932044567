import Vue from 'vue'
import Vuex from 'vuex'

import account from '@/store/modules/account'
import auditoriaMolex from '@/store/modules/auditoria-molex'
import customerStoreModule from '@/views/apps/customer/customerStoreModule'
import vendorStoreModule from '@/views/apps/vendor/vendorStoreModule'
import receiverStoreModule from '@/views/apps/receiver/receiverStoreModule'
import partStoreModule from '@/views/apps/part/partStoreModule'
import transportationLineStoreModule from '@/views/apps/transportation-lines/transportationLineStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    account,
    auditoriaMolex,
    'app-customers': customerStoreModule,
    'app-vendors': vendorStoreModule,
    'app-receivers': receiverStoreModule,
    'app-parts': partStoreModule,
    'app-transportation-lines': transportationLineStoreModule,
  },
  strict: process.env.DEV,
  state: {
    downloads: [],
  },
  mutations: {
    startDownload(state, download) {
      const downloadExists = state.downloads.find(d => d.id === download.id)
      if (downloadExists) {
        downloadExists.progress = 0
        downloadExists.loaded = 0
        downloadExists.total = 0
      } else {
        state.downloads.push(download)
      }
    },
    updateDownloadProgress(state, { id, loaded, total }) {
      const download = state.downloads.find(d => d.id === id)
      if (download) {
        download.loaded = loaded
        download.total = total
        download.progress = Math.round((loaded * 100) / total)
      }
    },
    finishDownload(state, id) {
      const download = state.downloads.find(d => d.id === id)
      if (download) {
        download.loaded = download.total
        download.progress = 100
        // setTimeout(() => {
        //   state.downloads = state.downloads.filter(d => d.id !== id)
        // }, 10000)
      }
    },
    removeDownload(state, id) {
      state.downloads = state.downloads.filter(d => d.id !== id)
    },
    removeAllCompletedDownloads(state) {
      state.downloads = state.downloads.filter(d => d.progress < 100)
    },
  },
  actions: {
    startDownload({ commit }, download) {
      commit('startDownload', download)
    },
    updateDownloadProgress({ commit }, progressData) {
      commit('updateDownloadProgress', progressData)
    },
    finishDownload({ commit }, id) {
      commit('finishDownload', id)
      // setTimeout(() => {
      //   commit('removeDownload', id)
      // }, 10000)
    },
    removeDownload({ commit }, id) {
      commit('removeDownload', id)
    },
    removeAllCompletedDownloads({ commit }) {
      commit('removeAllCompletedDownloads')
    },
  },
  getters: {
    downloads: state => state.downloads.slice().sort((a, b) => {
      if (a.mock) {
        return -2
      }

      if (a.progress === 100 && b.progress < 100) {
        return 1
      }

      if (a.progress < 100 && b.progress === 100) {
        return -1
      }

      return b.date - a.date
    }),
    hasIncompleteDownloads: state => state.downloads.some(download => (download.progress < 100) || (download.mock)),
  },
})
