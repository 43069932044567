<template>
  <div>
    <b-modal
      ref="ticketSurveyModal"
      centered
      size="lg"
      :ok-title="$t('AppHelpDesk.survey.modalAcceptText')"
      ok-only
      cancel-variant="outline-secondary"
      @ok="onSubmit"
      no-close-on-backdrop
      no-close-on-esc
    >
      <!-- hide-header-close -->
      <h4 class="textAlignCenter">
        {{ $t('AppHelpDesk.survey.modalTitle') }}
      </h4>
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <p class="textAlignCenter">
          {{
            $t("AppHelpDesk.survey.disclaimer").interpolate({
              ticketId: ticketData.ticketId,
              usuarioAsignado: ticketData.usuarioAsignadoNombreCompleto,
            })
          }}
        </p>
        <b-form-rating
          id="calificacionSatisfaccion"
          v-model="ticketData.calificacionSatisfaccion"
          no-border
          variant="warning"
          size="lg"
        />
        <b-form-textarea
          id="comentariosSatisfaccion"
          v-model="ticketData.comentariosSatisfaccion"
          rows="3"
          v-bind:placeholder="$t('AppHelpDesk.survey.commentsPlaceholder')"
        />
        <hr />
        <app-collapse
          type="border"
        >
          <app-collapse-item
            :title="$t('AppHelpDesk.survey.ticketInformation')"
          >
            <div class="containerScrollable">
              <b-container>
                <b-row>
                  <b-col
                    cols="12"
                    offset-md="1"
                    md="2"
                  >
                    <label for="ticketId">
                      <strong>{{ $t('AppHelpDesk.survey.ticketId') }}</strong>
                    </label>
                    <b-form-input
                      id="ticketId"
                      v-model="ticketData.ticketId"
                      readonly
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <label for="ticketCategoriaRutaMostrar">
                      <strong>{{ $t('AppHelpDesk.survey.categoryPath') }}</strong>
                    </label>
                    <b-form-input
                      id="ticketCategoriaRutaMostrar"
                      v-model="ticketCategoriaRutaMostrar"
                      readonly
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    offset-md="1"
                    md="5"
                  >
                    <label for="user">
                      <strong>{{ $t('AppHelpDesk.survey.user') }}</strong>
                    </label>
                    <b-form-input
                      id="user"
                      v-model="ticketData.usuarioReportaNombreCompleto"
                      readonly
                    />
                  </b-col>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <label for="assigned">
                      <strong>{{ $t('AppHelpDesk.survey.assigned') }}</strong>
                    </label>
                    <b-form-input
                      id="assigned"
                      v-model="ticketData.usuarioAsignadoNombreCompleto"
                      readonly
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    offset-md="1"
                    md="10"
                  >
                    <label for="titleError">
                      <strong>{{ $t('AppHelpDesk.survey.titleError') }}</strong>
                    </label>
                    <b-form-input
                      id="titleError"
                      v-model="ticketData.tituloError"
                      readonly
                    />
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="12"
                    offset-md="1"
                    md="10"
                  >
                    <label for="solutionDescription">
                      <strong>{{ $t('AppHelpDesk.survey.solutionDescription') }}</strong>
                    </label>
                    <b-form-textarea
                      id="solutionDescription"
                      v-model="ticketData.descripcionSolucion"
                      rows="3"
                      readonly
                    />
                  </b-col>
                </b-row>
              </b-container>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormInput,
  BModal,
  BFormRating,
  BFormTextarea,
  BRow,
  BCol,
  BContainer,
} from 'bootstrap-vue'
import {
  onMounted,
  onBeforeMount,
  ref,
} from '@vue/composition-api'
import common from '@/libs/common'
import ticketService from '@/services/helpDesk.service'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BForm,
    BFormInput,
    BModal,
    BFormRating,
    BFormTextarea,
    BRow,
    BCol,
    BContainer,
    AppCollapse,
    AppCollapseItem,
  },
  setup() {
    // eslint-disable-next-line no-extend-native, func-names
    String.prototype.interpolate = function (params) {
      const names = Object.keys(params)
      const vals = Object.values(params)
      // eslint-disable-next-line no-new-func
      return new Function(...names, `return \`${this}\`;`)(...vals)
    }

    const { getUserName } = common()
    const {
      fetchTicket,
      fetchTicketForSurvey,
      partialUpdateTicket,
    } = ticketService()

    const userName = ref(getUserName())
    const ticketCategoriaRutaMostrar = ref(null)
    const ticketData = ref({
      type: Object,
      require: true,
      default() {
        return {
          ticketId: 0,
        }
      },
    })
    const ticketSurveyModal = ref(null)
    const updatingTicketSurvey = ref(false)

    const fetchNextTicket = () => {
      if (userName) {
        fetchTicketForSurvey(userName.value, data => {
          ticketData.value = data
          if (ticketData.value) {
            ticketData.value.calificacionSatisfaccion = 1
            ticketCategoriaRutaMostrar.value = ticketData.value.ticketCategoria.rutaMostrar
            ticketSurveyModal.value.show()
          } else {
            ticketSurveyModal.value.hide()
          }
        })
      }
    }

    const onSubmit = () => {
      updatingTicketSurvey.value = true
      partialUpdateTicket(ticketData.value.ticketId, [
        {
          value: ticketData.value.calificacionSatisfaccion,
          path: 'calificacionSatisfaccion',
          op: 'replace',
        },
        {
          value: ticketData.value.comentariosSatisfaccion,
          path: 'comentariosSatisfaccion',
          op: 'replace',
        },
      ], () => {
        fetchNextTicket()
        updatingTicketSurvey.value = false
      })
    }

    onMounted(() => {
      fetchNextTicket()
    })
    onBeforeMount(() => {
    })

    return {
      userName,
      ticketData,
      ticketCategoriaRutaMostrar,
      fetchTicket,
      fetchTicketForSurvey,
      partialUpdateTicket,

      ticketSurveyModal,

      fetchNextTicket,
      onSubmit,
    }
  },
  beforeMount() {
    //
  },
  methods: {
    success(title, text) {
      this.$swal({
        title,
        text,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.textAlignCenter {
  text-align: center;
}
.containerScrollable {
  max-height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
