import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCustomers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Clientes', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, { clienteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Clientes/${clienteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Clientes', customerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editCustomer(ctx, customerData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Clientes/${customerData.clienteId}`, customerData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, { clienteId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/Clientes/${clienteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
