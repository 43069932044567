import { $themeBreakpoints } from '@themeConfig'
import moment from 'moment'
import optionService from '@/services/option.service'
import accountService from '@/services/account.service'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    fraccionesArancelariasLastUpdate: JSON.parse(localStorage.getItem('fracciones-arancelarias-lastupdate')) || moment().add(-1, 'days'),
    fraccionesArancelarias: JSON.parse(localStorage.getItem('fracciones-arancelarias')) || [],
    unreadNotificactions: JSON.parse(localStorage.getItem('unread-notifications')) || 0,
    unreadBulletins: JSON.parse(localStorage.getItem('unread-bulletins')) || 0,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    fraccionesArancelarias: state => state.fraccionesArancelarias,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_FRACCIONES_DATE() {
      localStorage.setItem('fracciones-arancelarias-lastupdate', JSON.stringify(moment()))
    },
    UPDATE_FRACCIONES(state, data) {
      localStorage.setItem('fracciones-arancelarias', JSON.stringify(data))
    },
    UPDATE_UNREAD_NOTIFICATIONS(state, data) {
      localStorage.setItem('unread-notifications', JSON.stringify(data))
    },
    UPDATE_UNREAD_BULLETINS(state, data) {
      localStorage.setItem('unread-bulletins', JSON.stringify(data))
    },
  },
  actions: {
    updateFracciones({ commit, state }) {
      if (moment().diff(state.fraccionesArancelariasLastUpdate, 'days') >= process.env.VUE_APP_ADUACORE_LOAD_FRACCIONES_DAYS) {
        const { fetchFracciones } = optionService()
        fetchFracciones(data => {
          commit('UPDATE_FRACCIONES', data)
          commit('UPDATE_FRACCIONES_DATE')
        })
      }
    },
  },
  updateUnreadNotifications({ commit }) {
    const { fetchUnreadNotificationsCount } = accountService()
    fetchUnreadNotificationsCount(data => {
      commit('UPDATE_UNREAD_NOTIFICATIONS', data)
    })
  },
  updateUnreadBulletins({ commit }) {
    const { fetchUnreadBulletinsCount } = accountService()
    fetchUnreadBulletinsCount(data => {
      commit('UPDATE_UNREAD_BULLETINS', data)
    })
  },
}
