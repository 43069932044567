import axios from '@axios'
import common from '@/libs/common'
import defaultService from './_default.service'

export default function partService() {
  const defaultMethods = defaultService('MesaAyuda')
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api/MesaAyuda`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchTickets = (data, callback) => defaultMethods.fetch('Ticket', { }, data, callback)
  const fetchCategorias = (data, callback) => defaultMethods.fetch('Categoria', { }, data, callback)
  const fetchTicket = (ticketId, callback) => defaultMethods.fetch('Ticket/%{ticketId}', { ticketId }, {}, callback)
  const fetchCategoria = (ticketCategoriaId, callback) => defaultMethods.fetch('Categoria/%{ticketCategoriaId}', { ticketCategoriaId }, {}, callback)
  const fetchTicketLog = (ticketId, callback) => defaultMethods.fetch('Ticket/%{ticketId}/Bitacora', { ticketId }, { }, callback)
  const fetchCategoryUser = (ticketCategoriaId, data, callback) => defaultMethods.fetch('UsuariosCategoria/%{ticketCategoriaId}', { ticketCategoriaId }, data, callback)
  const fetchSolvesTickets = (ticketId, data, callback) => defaultMethods.fetch('BancoSoluciones/%{ticketId}', { ticketId }, data, callback)
  const fetchActivityUsers = (data, callback) => defaultMethods.fetch('UsuariosActivos', { }, data, callback)
  const fetchActivityUsersWithActiveShift = (data, callback) => defaultMethods.fetch('UsuariosActivosConGuardiaActiva', { }, data, callback)
  const fetchActivityUser = (usuarioActivoId, callback) => defaultMethods.fetch('UsuariosActivos/%{usuarioActivoId}', { usuarioActivoId }, {}, callback)
  const fetchContactUser = (usuarioActivoId, callback) => defaultMethods.fetch('UsuariosActivosContacto/%{usuarioActivoId}', { usuarioActivoId }, {}, callback)
  const fetchIncidents = (data, callback) => defaultMethods.fetch('Incidencias', {}, data, callback)
  const fetchIncident = (incidenciaId, callback) => defaultMethods.fetch('Incidencias/%{incidenciaId}', { incidenciaId }, {}, callback)
  const fecthActiveIncident = callback => defaultMethods.fetch('IncidenciasActiva', {}, {}, callback)
  const fetchTicketForSurvey = (nombreUsuarioReporta, callback) => defaultMethods.fetch('Ticket/%{nombreUsuarioReporta}/TicketSinEvaluar', { nombreUsuarioReporta }, {}, callback)
  // const fetchTicketLog = (ticketId, callback) => {
  //   axios
  //     .get(`${API_URL}/Ticket/${ticketId}/Bitacora`)
  //     .then(response => {
  //       callback(response.data)
  //     })
  //     .catch(error => throwError(error))
  // }
  const createTicket = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Ticket`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateTicket = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Ticket/${data.ticketId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const partialUpdateTicket = (ticketId, data, callback) => {
    axios
      .patch(`${API_URL}/Ticket/${ticketId}`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const deleteTicket = ({ ticketId }, callback) => {
    axios
      .delete(`${API_URL}/Ticket/${ticketId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createCategory = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Categoria`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateCategoria = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Categoria/${data.ticketCategoriaId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createCategoryUser = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/UsuariosCategoria`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deleteCategoryUser = (ticketCategoriaUsuarioId, callback) => {
    axios
      .delete(`${API_URL}/UsuariosCategoria/${ticketCategoriaUsuarioId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const createActivityUser = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/UsuariosActivos`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateActivityUser = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/UsuariosActivos/${data.usuarioActivoId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const createIncident = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Incidencias`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const updateIncident = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Incidencias/${data.incidenciaId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  return {
    // Tickets
    fetchTickets,
    fetchTicket,
    createTicket,
    updateTicket,
    partialUpdateTicket,
    deleteTicket,
    fetchTicketLog,
    fetchTicketForSurvey,
    // Categorias
    fetchCategorias,
    createCategory,
    fetchCategoria,
    updateCategoria,
    // CategoriasUsuario
    fetchCategoryUser,
    createCategoryUser,
    deleteCategoryUser,
    // Banco de soluciones
    fetchSolvesTickets,
    // Usuarios activos
    fetchActivityUsers,
    fetchActivityUser,
    fetchActivityUsersWithActiveShift,
    fetchContactUser,
    createActivityUser,
    updateActivityUser,
    // Incidencias
    fecthActiveIncident,
    fetchIncidents,
    fetchIncident,
    createIncident,
    updateIncident,
  }
}
