import i18n from '@/libs/i18n'

export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/Home',
    name: 'home',
    component: () => import('@/views/pages/home/Home.vue'),
    meta: {
      resource: 'home',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/notifications',
    name: 'misc-notifications',
    component: () => import('@/views/pages/miscellaneous/Notifications.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
  {
    path: '/pages/miscellaneous/bulletins',
    name: 'misc-bulletins',
    component: () => import('@/views/pages/miscellaneous/Bulletins.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
    },
  },
  {
    path: '/pages/bulletins/customers',
    name: 'misc-bulletins-customers',
    component: () => import('@/views/pages/miscellaneous/CustomersBulletins.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      resource: 'profile',
      action: 'read',
      pageTitle: i18n.t('Routes.accountSettings'),
      breadcrumb: [
        {
          text: i18n.t('Routes.accountSettings'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/pdf-viewer:pdfUrl',
    name: 'pages-pdf-viewer',
    component: () => import('@/components/PdfViewer.vue'),
    meta: {
      pageTitle: i18n.t('Components.PdfViewer.Title'),
      layout: 'full',
      breadcrumb: [
        {
          text: i18n.t('Components.PdfViewer.Title'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/user/dashboard',
    name: 'pages-user-dashboard',
    component: () => import('@/views/pages/miscellaneous/UserDashboard.vue'),
    meta: {
      pageTitle: i18n.t('Routes.UserDashboard'),
      resource: 'Tableros',
      action: 'read',
      breadcrumb: [
        {
          text: i18n.t('Routes.UserDashboard'),
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/previous/viewer/:guid',
    name: 'pages-previous-viewer',
    component: () => import('@/views/pages/previous/PreviousViewer.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
    },
  },
  {
    path: '/archivo/digitalrecorddownload/:uuid/:nombre',
    name: 'digital-record-download',
    component: () => import('@/views/pages/Download/DigitalRecordDownload.vue'),
    meta: {
      resource: 'Auth',
      layout: 'full',
    },
  },
]
