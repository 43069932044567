import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReceivers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/Destinatarios', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchReceiver(ctx, { destinatarioId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/Destinatarios/${destinatarioId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReceiver(ctx, receiverData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/Destinatarios', receiverData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editReceiver(ctx, receiverData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/Destinatarios/${receiverData.destinatarioId}`, receiverData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReceiver(ctx, { destinatarioId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/Destinatarios/${destinatarioId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
