import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import 'vueditor/dist/style/vueditor.min.css'
import VueMask from 'v-mask'
import vuetify from '@/libs/vuetify'
import VueNativeNotification from 'vue-native-notification'
import VuePreferences from 'vue-preferences'
import i18n from '@/libs/i18n'
import { HubConnectionBuilder } from '@microsoft/signalr'
import moment from 'moment'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)
Vue.use(VueMask)
Vue.use(VueNativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
})
Vue.use(VuePreferences, {
})

// SignalR
const connection = new HubConnectionBuilder()
  .withUrl(`${process.env.VUE_APP_ADUACORE_API_URL}/`, { accessTokenFactory: () => localStorage.getItem('accessToken') })
  .build()

connection.start()
  .then(() => {})

connection.on('ReceiveMessage', message => {
  Vue.notification.show(`AduaCore - ${message.title}`, {
    body: message.body,
  },
  {
    onclick: () => router.push({ name: message.route, params: message.parameters }),
  })
})

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.filter('formatDate', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/yyyy')
  }
  return null
})
Vue.filter('formatDateTime', value => {
  if (value) {
    return moment(String(value)).format('DD/MM/yyyy HH:mm:ss')
  }
  return null
})

const stateExist = JSON.parse(localStorage.getItem('stateTime'))
if (!stateExist) {
  localStorage.setItem('stateTime', JSON.stringify([]))
}

new Vue({
  router,
  store,
  i18n,
  vuetify,
  methods: {
    getNow: () => {
      const today = new Date()
      const date = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
      const dateTime = `${date}'T'${time}`
      return dateTime
    },
  },
  render: h => h(App),
}).$mount('#app')
